import { LayouterRetailerNav } from './Nav';
import './style.scss';

//TODO: Create seperate style file for header and nav
export default function View() {
	return (
		<header className='header-container'>
			<LayouterRetailerNav />
		</header>
	);
}
