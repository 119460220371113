import { useEffect, useState } from "react";
import "./checkout.scss";
import { FluidCart } from "components/Cart";

export default function Checkout({
  firstComp,
  cartProps,
  user,
  onPurchase,
  onRemoveFromCart,
  giveCheckout,
  discountAmount = 0,
  discountCodeStatus,
  paypal,
  paypalRoutes,
  JWT,
}: any) {
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phoneNumber);

  //Address
  const [streetAddress1, setStreetAddress1] = useState(
    user?.address?.streetAddress1
  );

  const [streetAddress2, setStreetAddress2] = useState(
    user?.address?.streetAddress2
  );
  const [city, setCity] = useState(user?.address?.city);
  const [state, setState] = useState(user?.address?.state);
  const [country, setcountry] = useState(user?.address?.country);
  const [zipCode, setZipCode] = useState(user?.address?.zipCode);

  //Card
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvc, setCVC] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("stripe");

  //Komono
  const [discountCode, setDiscountCode] = useState("");

  useEffect(() => {
    if (user) {
      const userCopy = JSON.parse(JSON.stringify(user));
      console.log("user", user);
      console.log("user.firstName", userCopy.firstName);
      setFirstName(userCopy.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setStreetAddress1(user.address?.streetAddress1);
      setStreetAddress2(user.address?.streetAddress2);
      setCity(user.address?.city);
      setState(user.address?.state);
      setZipCode(user.address?.zipCode);
      setcountry(user.address?.country);
      setPhone(user?.phoneNumber);
      giveCheckout({
        firstName: userCopy.firstName,
        lastName: user.lastName,
        email: user.email,
        streetAddress1: user.address?.streetAddress1,
        streetAddress2: user.address?.streetAddress2,
        city: user.address?.city,
        state: user.address?.state,
        zipCode: user.address?.zipCode,
        country: user.address?.country,
        phone: user?.phoneNumber,
      });
    }
  }, [user]);

  useEffect(() => {
    if (paymentMethod !== "paypal") {
      document
        .querySelector(".payment_type_selector")
        ?.classList.add("stripe_mode");
      document
        .querySelector(".payment_type_selector")
        ?.classList.remove("paypal_mode");
    } else {
      document
        .querySelector(".payment_type_selector")
        ?.classList.add("paypal_mode");
      document
        .querySelector(".payment_type_selector")
        ?.classList.remove("stripe_mode");
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (!paypal) return;

    paypal
      .Buttons({
        // Order is created on the server and the order id is returned
        createOrder() {
          return fetch(paypalRoutes.createOrder, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // use the "body" param to optionally pass additional order information
            // like product skus and quantities

            body: JSON.stringify({
              cart: cartProps.cart,
              checkout: getCheckoutInstantly("test", ""),
              JWT,
            }),
          })
            .then((response) => response.json())
            .then((order) => order.id);
        },
        // Finalize the transaction on the server after payer approval
        onApprove(data: any) {
          onPurchaseLocal(data?.orderID);
        },
      })
      .render("#paypal-button-container");
  });

  return (
    <div
      className={`checkout-container retailer-ui-container ${
        firstComp ? "first-comp" : ""
      }`}
    >
      <img className="logo" src="/dev/logo.png" />
      <label className="category">
        <strong>Contact information</strong>
      </label>
      <label>Email</label>
      <input
        type="email"
        defaultValue={email}
        onChange={(e) => {
          setEmail(e.target.value);
          giveCheckout(getCheckoutInstantly("email", e.target.value));
        }}
      />

      <span className="shared-row-left-container">
        <label>First Name</label>
      </span>
      <span className="shared-row-right-container">
        <label>Last Name</label>
      </span>
      <span className="shared-row-left-container">
        <input
          defaultValue={firstName}
          className="shared-row-left"
          type="text"
          onChange={(e) => {
            setFirstName(e.target.value);
            giveCheckout(getCheckoutInstantly("firstName", e.target.value));
          }}
        />
      </span>
      <span className="shared-row-right-container">
        <input
          defaultValue={lastName}
          className="shared-row-right"
          type="text"
          onChange={(e) => {
            setLastName(e.target.value);
            giveCheckout(getCheckoutInstantly("lastName", e.target.value));
          }}
        />
      </span>

      <label className="category">
        <strong>Shipping Information</strong>
      </label>
      <label>Street Address 1</label>
      <input
        type="text"
        defaultValue={streetAddress1}
        onChange={(e) => {
          setStreetAddress1(e.target.value);
          giveCheckout(getCheckoutInstantly("streetAddress1", e.target.value));
        }}
      />
      <label>Street Address 2</label>
      <input
        type="text"
        defaultValue={streetAddress2}
        onChange={(e) => {
          setStreetAddress2(e.target.value);
          giveCheckout(getCheckoutInstantly("streetAddress2", e.target.value));
        }}
      />

      <span className="shared-row-left-container">
        <label>City</label>
      </span>
      <span className="shared-row-right-container">
        <label>State / Province</label>
      </span>
      <span className="shared-row-left-container">
        <input
          type="text"
          defaultValue={city}
          onChange={(e) => {
            setCity(e.target.value);
            giveCheckout(getCheckoutInstantly("city", e.target.value));
          }}
        />
      </span>
      <span className="shared-row-right-container">
        <input
          type="text"
          defaultValue={state}
          onChange={(e) => {
            setState(e.target.value);
            giveCheckout(getCheckoutInstantly("state", e.target.value));
          }}
        />
      </span>
      <span className="shared-row-left-container">
        <label>Postal Code / Zip</label>
      </span>
      <span className="shared-row-right-container">
        <label>Country</label>
      </span>
      <span className="shared-row-left-container">
        <input
          type="text"
          defaultValue={zipCode}
          onChange={(e) => {
            giveCheckout(getCheckoutInstantly("zipCode", e.target.value));
            setZipCode(e.target.value);
          }}
        />
      </span>
      <span className="shared-row-right-container">
        <input
          type="text"
          defaultValue={country}
          onChange={(e) => {
            giveCheckout(getCheckoutInstantly("country", e.target.value));
            setcountry(e.target.value);
          }}
        />
      </span>
      <label>Phone Number</label>
      <input
        type="tel"
        defaultValue={phone}
        onChange={(e) => {
          setPhone(e.target.value);
          giveCheckout(getCheckoutInstantly("phone", e.target.value));
        }}
      />

      <div className="extra_margin"></div>
      <FluidCart
        {...cartProps}
        excludeCheckout={true}
        excludeNotes={true}
        onRemoveFromCart={onRemoveFromCart}
      />
      <label>
        Apply Coupon Code{" "}
        <strong>
          <span
            className={
              discountStatusMap[discountCodeStatus || "default"].className
            }
          >
            {(discountCodeStatus || "").indexOf("Valid") >= 0
              ? `${discountCodeStatus}${discountAmount}`
              : discountCodeStatus
              ? discountCodeStatus
              : ""}
          </span>
        </strong>
      </label>
      <input
        type="text"
        onChange={(e) => {
          setDiscountCode(e.target.value);
          giveCheckout(getCheckoutInstantly("discountCode", e.target.value));
        }}
        placeholder="jfsdi2nsdfjk2"
      />

      <section className="payment-container">
        <div
          className="card-tab"
          onClick={() => {
            setPaymentMethod("stripe");
          }}
        >
          <img src="/icon/card.svg" />
          <p>Card</p>
        </div>
        <div
          className="paypal-tab"
          onClick={() => {
            setPaymentMethod("paypal");
          }}
        >
          <img className="paypalButton" src="/icon/PayPal.svg" />
        </div>
        <hr className="payment_type_selector" />
        {paymentMethod !== "paypal" ? (
          <>
            <label>Credit Card Number</label>
            <input
              type="text"
              defaultValue={cardNumber}
              onChange={(e) => {
                setCardNumber(e.target.value);
                giveCheckout(
                  getCheckoutInstantly("cardNumber", e.target.value)
                );
              }}
            />

            <span className="shared-row-left-container">
              <label>Expiration</label>
            </span>
            <span className="shared-row-right-container">
              <label>CVC</label>
            </span>
            <span className="shared-row-left-container">
              <input
                type="text"
                value={expirationDate}
                onChange={(e) => {
                  let target = e.target,
                    position = target.selectionStart,
                    length = target.value.length;

                  target.value = target.value
                    .replace(/[^\d]/g, "")
                    .replace(/(.{2})/, "$1/");

                  if (position !== length) {
                    target.selectionEnd = position;
                  }
                  let value = e.target.value;

                  //Check if value is a string with a length greater than 5.
                  // If it is then we need to remove the last character.
                  if (typeof value === "string" && value.length > 5) {
                    value = value.slice(0, -1);
                  }

                  setExpirationDate(value);
                  giveCheckout(getCheckoutInstantly("expirationDate", value));
                }}
              />
            </span>
            <span className="shared-row-right-container">
              <input
                type="text"
                defaultValue={cvc}
                onChange={(e) => {
                  setCVC(e.target.value);
                  giveCheckout(getCheckoutInstantly("cvc", e.target.value));
                }}
              />
            </span>
          </>
        ) : (
          <></>
        )}
        <PurchaseButton />
      </section>
    </div>
  );

  function PurchaseButton() {
    return (
      <>
        <button
          className={`purchase_action ${
            paymentMethod === "paypal" ? "d-none" : ""
          }`}
          onClick={onPurchaseLocal}
        >
          Purchase
        </button>
        <div
          className={`purchase_action ${
            paymentMethod !== "paypal" ? "d-none" : ""
          }`}
          id="paypal-button-container"
        ></div>
      </>
    );
  }

  function getCheckoutInstantly(type: string, value: string) {
    let checkout: any = {
      streetAddress1,
      streetAddress2,
      city,
      state,
      country,
      zipCode,
      firstName,
      lastName,
      email,
      phone,
      cardNumber,
      expirationDate,
      cvc,
      discountCode,
    };

    checkout[type] = value;

    return checkout;
  }

  function onPurchaseLocal(paypalOrderID?: any) {
    onPurchase({
      firstName,
      lastName,
      email,
      streetAddress1,
      streetAddress2,
      city,
      state,
      zipCode,
      cardNumber,
      expirationDate,
      cvc,
      country,
      phone,
      discountCode,
      paypalOrderID,
    });
  }
}

const discountStatusMap: any = {
  "Coupon is Invalid": {
    className: "invalid_text",
  },
  "Coupon is Valid. Applying Discount of $": {
    className: "success_text",
  },
  "Analyzing Coupon": {
    className: "",
  },
  default: {
    className: "",
  },
};
