import { useEffect, useState } from "react";
import FormatedFilter, {
  Price,
  SingleColor,
  SingleCategory,
  Color,
  Category,
} from "./FormatedFilterType";

const colorMap: any = {
  white: "#ffffff",
  black: "#000000",
  red: "#ff0000",
  orange: "#ffa500",
  purple: "#800080",
};

export default function useFormatedFilter(rawFilter: any = []) {
  const [formatedFilter, setFormatedFilter] = useState<FormatedFilter>([]);
  useEffect(() => {
    if (!rawFilter.length) return;
    const formatedFilter: FormatedFilter = [];

    for (let i = 0; i < rawFilter.length; i++) {
      const singleRawFilter = rawFilter[i];
      let singleFormatedFilter = formatSingleRawFilter(singleRawFilter);
      if (typeof singleFormatedFilter !== "undefined" || "null")
        formatedFilter.push(singleFormatedFilter);
    }
    setFormatedFilter(formatedFilter);
  }, [rawFilter]);

  function formatSingleRawFilter(rawFilter: any): Category | Color | Price {
    let singleFilter: any;
    switch (rawFilter._type) {
      case "colorFilter":
        singleFilter = {
          title: rawFilter.title,
          choices: [],
          type: rawFilter._type,
        };
        for (let i = 0; i < rawFilter.colorFilter.length; i++) {
          const rawChoice = rawFilter.colorFilter[i];
          const colorFilterChoice: SingleColor =
            getSingleFormatedColorChoice(rawChoice);
          singleFilter.choices.push(colorFilterChoice);
        }
        break;
      case "categoryFilter":
        singleFilter = {
          title: rawFilter.title,
          choices: (() => {
            const choices: SingleCategory[] = [];
            for (let i = 0; i < rawFilter.categoryFilter.length; i++) {
              const rawChoice = rawFilter.categoryFilter[i];
              const categoryFilterChoice: SingleCategory =
                getSingleFormatedCategoryChoice(rawChoice);
              choices.push(categoryFilterChoice);
            }
            return choices;
          })(),
          type: rawFilter._type,
        };
        break;
      case "priceFilter":
        console.log("Price raw filter:", rawFilter);
        singleFilter = getSingleFormatedPriceChoice(rawFilter);
        break;
      default:
        break;
    }
    return singleFilter;
  }

  function getSingleFormatedCategoryChoice(
    rawCategoryChoice: any
  ): SingleCategory {
    const singleCategoryChoice: SingleCategory = {
      category: rawCategoryChoice.category,
      enable: rawCategoryChoice.enable,
    };
    return singleCategoryChoice;
  }

  function getSingleFormatedPriceChoice(rawPriceChoice: any): Price {
    let singleFilter: Price = {
      title: rawPriceChoice.title,
      min: rawPriceChoice.minPrice,
      max: rawPriceChoice.maxPrice,
      type: rawPriceChoice._type,
    };
    return singleFilter;
  }

  function getSingleFormatedColorChoice(rawChoice: any): SingleColor {
    const category = rawChoice.color;
    const colorHex = colorMap[category];
    const enable = rawChoice.enable;
    return {
      category,
      color: colorHex,
      enable,
    };
  }

  return [formatedFilter, setFormatedFilter];
}
