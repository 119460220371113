import React, { useEffect, useState } from 'react';
import './style.scss';
export default function SignOut({ className, signOut }: { className?: string; signOut: Function }) {
	const [display, setDisplay] = useState(`We are currently signing you out :)`);
	useEffect(() => {
		signOut();
		setDisplay(`You have been signed out :)`);
	}, []);
	return <div className={`${className ? className : ''}`}>{display}</div>;
}
