import { useContext, useEffect, useState } from 'react';
//@ts-ignore
import { layouterContext } from 'layouter-tools';
import { getURLFromSanityRefrence } from 'components/Utilities';
import { changeURLContext } from 'components/Utilities';
import FunnelItem02 from './index';
import './style.scss';
export default function FunnelView(props: any) {
	//@ts-ignore
	const { client } = useContext(layouterContext) || {};
	const [imageURL, setImageURL] = useState<any>(null);
	const { handleActionButton } = useContext(changeURLContext);

	useEffect(() => {
		if (imageURL) return;
		getURLFromSanityRefrence(props.displayImage, client)
			.then((url: any) => {
				setImageURL(url);
			})
			.catch(console.error);
	}, [imageURL]);

	return (
		<FunnelItem02
			displayImage={imageURL}
			customMarginPadding={props.sectionMarginAndPadding}
			title={props.title}
			subtitle={props.subtitle}
			actionText={props.actionButton}
			onAction={() => {
				handleActionButton(props);
			}}
		/>
	);
}
