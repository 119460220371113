import React from "react";
import "./auth.scss";
import { auth } from "components/Utilities";
import { useFlash } from "components/FlashMessage";

type Props = {
  className: string;
  children: JSX.Element;
  fetchSignUp?: Function;
  fetchSignIn?: Function;
};

export default function AuthContainer({
  className,
  children,
  fetchSignUp,
  fetchSignIn,
  ...props
}: Props) {
  const { flashRequest, abortTimeout } = useFlash();

  return (
    <div className={`retailer-ui-container ${className}`} {...props}>
      <div className="auth-container">
        {React.cloneElement(children, { onSignUp, onSignIn })}
      </div>
    </div>
  );

  async function onSignUp(user: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    rememberMe: boolean;
  }) {
    const validation = auth.getSignUpValidation(user);
    if (validation.isValid && fetchSignUp) {
      let cashedFetchSignUp = await fetchSignUp(user);

      return cashedFetchSignUp;
    } else {
      flashRequest({
        type: "error",
        message: validation.message as string,
        title: "Invalid Credentials",
      });
      return validation.message;
    }
  }

  async function onSignIn(user: {
    email: string;
    password: string;
    rememberMe: boolean;
  }) {
    const timeoutFlashID = await flashRequest({
      type: "error",
      message: "Failed to get get response from server in time",
      title: "Something went wrong",
      timeOut: 5000,
    });
    const validation = auth.validateUserSignIn(user);
    if (validation.isValid && fetchSignIn)
      return await fetchSignIn(user, { timeoutFlashID });
    else {
      flashRequest({
        type: "error",
        message: validation.message as string,
        title: "Invalid Credentials",
      });
      return validation.message;
    }
  }
}
