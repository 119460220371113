import React from "react";
import "./cart.scss";
import CartCore from "./CartCore";
export default function Cart(props: any) {
  return (
    <div
      className={`cart-container retailer-ui-container ${
        props.firstComp ? "first-comp" : ""
      }`}
    >
      <CartCore {...props} capitalizeAndAddSpaces={capitalizeAndAddSpaces} />
    </div>
  );
}

export const FluidCart = (props: any) => {
  return (
    <div className={`cart-container ${props.firstComp ? "first-comp" : ""}`}>
      <CartCore {...props} capitalizeAndAddSpaces={capitalizeAndAddSpaces} />
    </div>
  );
};

function capitalizeAndAddSpaces(str: string) {
  if (str.length === 0) {
    return str; // Return an empty string if input is empty
  }

  const capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalizedStr.replace(/([A-Z])/g, " $1");
}
