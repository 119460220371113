import Confirmation from "./Confirmation";
import { cartContext } from "components/Cart";
import { useContext, useState, useEffect } from "react";
function LayouterRetailerConfirmation({ firstComp }: any) {
  const { lastPurchase, setLastPurchase }: any = useContext(cartContext);
  const [lastPurchaseBuffer, setLastPurchaseBuffer] = useState(null);

  useEffect(() => {
    if (!lastPurchase || !lastPurchase.length) return;
    setLastPurchaseBuffer(lastPurchase);
    setLastPurchase(null);
  }, [lastPurchase]);

  return <Confirmation firstComp={firstComp} cart={lastPurchaseBuffer} />;
}

export default LayouterRetailerConfirmation;
