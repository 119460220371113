import { createContext, useState, useEffect } from "react";
import { authRoute, localStorage } from "utilities";

//TODO: Impliment sign out via page
//Make sign in work properly
//Create sanity build for production version of app
// push sanity build to production
//Update sanitys production DB
//Push changes from server to heroku
//Fill in any relivent env vars
//push client code
//Deploy sign in and sign up

const authenticationContext: any = createContext(null);
export function AuthenticationProvider({ children }: any) {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const JWT = localStorage.getObject("userJWT");
    fetch(`${authRoute}/user?jwt=${JWT}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if (!res.ok) return Promise.reject(await res.json());
        return res.json();
      })
      .then((res) => {
        setUser({ ...res.user, JWT });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <authenticationContext.Provider value={{ user, setUser }}>
      {children}
    </authenticationContext.Provider>
  );
}
export default authenticationContext;
