import { useState, useEffect } from "react";
import useFormatedProducts from "./useFormatedProducts";
import useFormatedFilter from "./useFormatedFilter";
import Product from "components/ProductCard/productCardType";
import Filter, {
  Color,
  Category,
  SingleFilter,
  getSingleFilterTypeFromSingleFilter,
  Price,
} from "./FormatedFilterType";
import productCard from "components/ProductCard/productCardType";

export default function useFilterdProducts(
  rawFilter: any = [],
  formattedFilter?: any
) {
  //TODO: Optomitze this so it doesn't have to run twice
  const formatedFilterPack = useFormatedFilter(rawFilter);
  const formatedFilter = formattedFilter || (formatedFilterPack[0] as Filter);

  const products = useFormatedProducts();
  const [filterdProducts, setFilterdProducts] = useState<productCard[]>([]);

  useEffect(() => {
    const filterdProducts = getFilterdProducts(products, formatedFilter);
    setFilterdProducts(filterdProducts);
  }, [products, formatedFilter]);

  return filterdProducts;

  function getFilterdProducts(
    products: Product[],
    formatedFilter: Filter
  ): Product[] {
    if (!formatedFilter.length) return products;
    let filterdProducts: Product[] = products as Product[]; //TODO make it so useProducts returns an array of objects are of type Product
    for (let i: number = 0; i < formatedFilter.length; i++) {
      let currentFilter: SingleFilter = formatedFilter[i];
      filterdProducts = screenProductsAgainstFilter(
        filterdProducts,
        currentFilter
      );
    }

    return filterdProducts;
  }
}

function screenProductsAgainstFilter(
  products: Product[],
  Filter: SingleFilter
): Product[] {
  let filterdProducts: Product[] = products;
  const type = getSingleFilterTypeFromSingleFilter(Filter);

  switch (type) {
    case "Price":
      filterdProducts = screenProductsAgainstPriceFilter(products, Filter);
      break;
    case "Color":
      filterdProducts = screenProductsAgainstCategoryOrColorFilter(
        products,
        Filter as Category | Color
      );
      break;
    case "Category":
      filterdProducts = screenProductsAgainstCategoryOrColorFilter(
        products,
        Filter as Category | Color
      );
      break;
    default:
      break;
  }

  return filterdProducts;
}

function screenProductsAgainstPriceFilter(
  products: Product[],
  Filter: Price
): Product[] {
  const filterdProducts: Product[] = [];
  console.log("Price products:", products);
  for (let i = 0; i < products.length; i++) {
    const currentProduct = products[i];
    if (!Filter?.min) Filter.min = 0;
    const matchesMin = Filter.min <= currentProduct.price;
    const matchesMax = Filter.max ? Filter.max >= currentProduct.price : true;
    if (matchesMin && matchesMax) filterdProducts.push(currentProduct);
  }
  return filterdProducts;
}

function screenProductsAgainstCategoryOrColorFilter(
  products: Product[],
  Filter: Category | Color
): Product[] {
  const filterdProducts: Product[] = [];
  for (let i = 0; i < products.length; i++) {
    let currentProduct = products[i];
    let productHasCategoryFlag = false;
    if (!currentProduct.categories) continue;
    for (let j = 0; j < currentProduct.categories.length; j++) {
      const currentProductCategory = currentProduct.categories[j];
      for (let k = 0; k < Filter.choices.length; k++) {
        const currentChoice = Filter.choices[k] || {};
        const { category, enable } = currentChoice;
        if (enable && currentProductCategory === category) {
          productHasCategoryFlag = true;
          break;
        }
      }
      if (productHasCategoryFlag) break;
    }
    if (productHasCategoryFlag) filterdProducts.push(currentProduct);
  }
  return filterdProducts;
}
