import React from "react";
import "./product.scss";
import { SFW } from "Config";

export default function Product(props: any) {
  const {
    title = "Trinidad Twopice",
    subtitle = "The Trinidad Twopice",
    description = `Morbi tellus nisl, accumsan quis bibendum in, vulputate nec arcu. Fusceeto purus tellus. Quisque
    neque mi, sollicitdin in nibh eget, euismod blandituris Sed accumsan maximus fermentum. Pellentesque
    leogranto tortor.`,
    firstComp,
    price = 53.2,
    shipingPrice = 20,
    productImage = SFW ? "dev/case.png" : "dev/product-thumbnail.png",
    previews = [
      "dev/case-2.png",
      "dev/case-3.png",
      "dev/case-2.png",
      "dev/case-3.png",
      "dev/case-2.png",
    ],
    onAddToCart = () => {
      console.log("onAddToCart not implemented");
    },
    onBuyNow = () => {
      console.log("onBuyNow not implemented");
    },
  } = props;

  return (
    <div
      className={`retailer-ui-container product-container ${
        firstComp ? "first-comp-margin" : ""
      }`}
    >
      <section>
        <div className="main_display_img_container">
          <img src={productImage} />
        </div>
        {previews ? (
          previews.map((src: string, i: number) => {
            return <img className="previews" key={i} src={src} />;
          })
        ) : (
          <></>
        )}
        {/* Todo, make image number dynamic*/}
        {/* Consider creating a state and an effect that toggles the number of images visable if you press see more button */}
      </section>
      <section>
        <h4>{title}</h4>
        <div className="price-rateing-container">
          <p>{`$${price} - $${price + shipingPrice}`}</p>
          {/* <div>
            <img />
            <img />
            <img />
            <img />
            <img />
          </div> */}
        </div>
        <p className="description">{description}</p>
        <h5>Size</h5>
        <p className="guide-text">choose an option</p>
        <div className="size-container">
          <button className="button-outline">S</button>
          <button className="button-outline">M</button>
          <button className="button-outline">L</button>
          <button className="button-outline">XL</button>
        </div>
        <h5>Color</h5>
        <p className="guide-text">choose an option</p>
        <div className="color-input-container">
          <div className="white-input" />
          <div className="red-input" />
          <div className="orange-input" />
          <div className="purple-input" />
        </div>
        <div className="action-button-container">
          <button onClick={onBuyNow} className="button-outline">
            BUY NOW
          </button>
          <button onClick={onAddToCart}>ADD TO CART</button>
        </div>
      </section>
    </div>
  );
}
