import "./style.scss";
import ProductCard from "components/ProductCard";
import { useMediaQuery } from "react-responsive";
import useFormatedProducts from "./useFormatedProducts";

//TODO: rename useProducts to rawProductDisplay.
//TODO: create a formater hook to format the raw product display.
//TODO: Map formated data to array of product cards.
export default function ProductDisplay(productDisplay: {
  products: any[];
  className?: string;
}) {
  const { products, className } = productDisplay;
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
  const isLG = useMediaQuery({ query: "(max-width: 1500px)" });
  const isMD = useMediaQuery({ query: "(max-width: 1200px)" });

  type className = {
    xs: string;
    sm: string;
    md: string;
  };

  return (
    <div
      className={`product-display ${
        isMobile ? "mobile" : isMD ? "md" : isLG ? "lg" : "xl"
      } retailer-ui-container`}
    >
      {products ? (
        products.map((product: any, i: number) => {
          return <ProductCard key={i} {...product} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
}
