import { useState, useContext } from "react";
import AuthContainer from "components/AuthContainer";
import authenticationContext from "components/Authentication";
import { changeURLContext } from "components/Utilities";

export default function SignUpContainer({
  className,
  forgotPassword,
  ...rest
}: {
  className?: string;
  fetchSignUp: Function;
  forgotPassword?: Function;
}) {
  return (
    <AuthContainer className={`sign-up-container ${className}`} {...rest}>
      <SignUp forgotPassword={forgotPassword} />
    </AuthContainer>
  );
}

function SignUp({
  onSignUp,
  forgotPassword,
}: {
  onSignUp?: Function;
  forgotPassword?: Function;
}) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState<any>(null);
  const { changeURL }: any = useContext(changeURLContext);
  return (
    <>
      <h4>Sign Up</h4>
      {error ? <p className="error-message">{error}</p> : <></>}
      <label>First Name</label>
      <input
        type="text"
        onInput={(e: any) => {
          setFirstName(e.target.value);
        }}
      />
      <label>Last Name</label>
      <input
        type="text"
        onInput={(e: any) => {
          setLastName(e.target.value);
        }}
      />
      <label>Email</label>
      <input
        type="text"
        onInput={(e: any) => {
          setEmail(e.target.value);
        }}
      />
      <label>Password</label>
      <input
        type="password"
        onInput={(e: any) => {
          setPassword(e.target.value);
        }}
      />
      <button
        className="forgot-password nav-link link-blue un-button-to-link"
        onClick={() => {
          forgotPassword
            ? forgotPassword()
            : console.log("forgot password is not implimented");
        }}
      >
        Forgot Password?
      </button>
      <button
        onClick={async () => {
          const user = { email, firstName, lastName, password, rememberMe };
          // calls onSignUp and logs an error is there is one
          if (onSignUp) setError(await onSignUp(user));
        }}
      >
        Sign Up
      </button>
      <div className="remember-me-container">
        <input
          type="checkbox"
          onInput={(e: any) => {
            setRememberMe(e.target.checked);
          }}
        />
        <p>Remember Me?</p>
      </div>

      <p className="alread-have-account">
        Already Have an account?{" "}
        <a href="#" className="nav-link link-blue">
          Sign in here.
        </a>
      </p>
    </>
  );
}
