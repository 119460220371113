import AuthContainer from "components/AuthContainer";
import React, { useState } from "react";
export default function SignInContainer({
  className,
  fetchSignIn,
  forgotPassword,
}: {
  className?: string;
  fetchSignIn: Function;
  forgotPassword?: Function;
}) {
  return (
    <AuthContainer
      className={`sign-up-container ${className}`}
      fetchSignIn={fetchSignIn}
    >
      <SignIn forgotPassword={forgotPassword} />
    </AuthContainer>
  );
}

function SignIn({
  onSignIn,
  forgotPassword,
}: {
  onSignIn?: Function;
  forgotPassword?: Function;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState<any>(null);
  return (
    <>
      <h4>Sign In</h4>
      {error ? <p className="error-message">{error}</p> : <></>}
      <label>Email</label>
      <input
        type="text"
        onInput={(e: any) => {
          setEmail(e.target.value);
        }}
      />
      <label>Password</label>
      <input
        type="password"
        name="password"
        onInput={(e: any) => {
          setPassword(e.target.value);
        }}
      />
      <button
        className="forgot-password nav-link link-blue un-button-to-link"
        onClick={() => {
          forgotPassword
            ? forgotPassword()
            : console.log("forgot password is not implimented");
        }}
      >
        Forgot Password?
      </button>
      <button
        onClick={async () => {
          const user = { email, password, rememberMe };
          if (onSignIn) await onSignIn(user);
        }}
      >
        Sign In
      </button>
      <div className="remember-me-container">
        <input
          type="checkbox"
          onInput={(e: any) => {
            setRememberMe(e.target.checked);
          }}
        />
        <p>Remember Me?</p>
      </div>

      <p className="alread-have-account">
        Don't have an account?{" "}
        <a href="#" className="nav-link link-blue">
          Sign up here.
        </a>
      </p>
    </>
  );
}
