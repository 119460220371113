import { useEffect } from "react";
import "./flashMessage.scss";

const SUCCESS_PATH = "images/flash_success_background_01.png";

const CLOSE_PATH = "icon/close_white.svg";
const CHECK_Path = "icon/check_white.svg";

function FlashMessage({}: any) {
  return (
    <div id="original_flash" className="flash_message_container hidden">
      <img alt="background" className="background_image" src={SUCCESS_PATH} />
      <button
        onClick={() => {
          console.log("clicked");
        }}
        className="exit_button"
      >
        <img src={CLOSE_PATH} alt="exit" />
      </button>
      <section className="icon_side">
        <span>
          <img className="icon" src={CHECK_Path} alt="check mark" />
        </span>
      </section>
      <section className="text_side">
        <h1>SUCCESS</h1>
        <p>
          Nullam et sapien ac elit tempor venenatis eget et purus. Morbi
          accumsan ac risus ac sagittis. Sed pulvinar arcu ac quam dapibus
          luctus. Aliquam consequat ante luctus volutpat venenatis. Duis luctus
          condimentum purus, ac interdum mauris.
        </p>
      </section>
    </div>
  );
}

export default FlashMessage;
