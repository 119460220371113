import React from "react";
import "./style.scss";
import { isDev } from "components/Utilities";

export default function FunnelItem04({
  onAction,
  displayImage,
  title,
  subtitle,
  backgroundColor,
  textColor,
  customMarginPadding,
  logo,
}: any) {
  return (
    <div
      className="funnel-item-04-container"
      style={customMarginPadding}
      onClick={onAction}
    >
      <div className="retailer-ui-container">
        <img
          src={displayImage ? displayImage : "/dev/FI04-01.pnga"}
          className="display-image"
          alt="Clothing display"
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "#ffffff",
          }}
        />
        <section
          className="text-content-container"
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "#ffffff",
          }}
        >
          <div className="text-center-container">
            <img className="logo" src={logo} />
            <h3
              style={{
                color: textColor ? textColor : "#000000",
              }}
            >
              {title}
            </h3>
            <p
              style={{
                color: textColor ? textColor : "#000000",
              }}
            >
              {subtitle}
            </p>
            <button
              style={{
                color: textColor ? textColor : "#000000",
              }}
              onClick={onAction}
              className="action-button nav-link"
            >
              SHOP NOW
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}
