import { useEffect, useState } from "react";
import client from "utilities/sanityClient";

//Put in a sanity asset object and get back a url

function useAsset(asset: any) {
  const [assetUrl, setAssetUrl] = useState();
  useEffect(() => {
    if (!asset) return;
    const query = `*[_id == "${asset._ref}"]`;
    client
      .fetch(query)
      .then((asset: any) => setAssetUrl(asset[0]?.url))
      .catch((err: any) => console.error(err));
  }, [asset]);

  return assetUrl;
}

export default useAsset;
