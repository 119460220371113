import { changeURLContext } from "components/Utilities";
import { settingsContext } from "components/Settings";
import { useContext } from "react";

export default function useForgotPassword() {
  const { handleActionButton, changeURL } = useContext(changeURLContext);
  const { settings }: any = useContext(settingsContext);
  return function forgotPassword() {
    if (!settings) return;
    handleActionButton(settings.forgotPassword);
  };
}
