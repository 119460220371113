import React from "react";
import "./funnelItem06.scss";
export default function FunnelItem06({
  firstComp = true,
  displayImage = "/dev/about.png",
  description = "tisan readymade intelligentsia woke ennui. Raw denim everyday carry craft beer lumbersexual shoreditch vaporware messenger bag. Synth hexagon swag authentic, blog tofu photo booth lo-fi typewriter four dollar toast try-hard DIY fingerstache XOXO. DIY migas leggings distillery taiyaki snackwave activated charcoal etsy art party twee deep v letterpress lyft. S",
  backgroundColor = "#FCAA67",
  customMarginPadding,
  title = "About Usa",
  textColor = "#000000",
}: any) {
  return (
    <div
      className={`funnel-item-06-container ${
        firstComp ? "first-comp-margin" : ""
      }`}
      style={
        customMarginPadding
          ? { ...customMarginPadding, backgroundColor }
          : { backgroundColor }
      }
    >
      <section>
        <img src={displayImage} />
      </section>
      <section style={{ backgroundColor }}>
        <h5
          style={{
            color: textColor,
          }}
        >
          {title}
        </h5>
        <p
          style={{
            color: textColor,
          }}
        >
          {description}
        </p>
      </section>
    </div>
  );
}
