import { useContext } from "react";
import Footer from "./Footer";
import footerContext from "./FooterContext";
import { createMarginPaddingStyles } from "components/Utilities";
import { changeURLContext } from "components/Utilities";
import { settingsContext } from "components/Settings";

function LayouterRetailerFooter() {
  const { footerLinks, socialLinks, footerCustomMarginPadding } =
    useContext(footerContext);
  const { createOnClickFromPopulatedActionButton, changeURL } =
    useContext(changeURLContext);
  const { settings, homePageSlug } = useContext(settingsContext);
  const marginAndPadding = createMarginPaddingStyles(footerCustomMarginPadding);
  const logo = {
    src: settings?.siteLogo?.asset?.url,
    onClick: () => changeURL("changePage", homePageSlug, ""),
  };

  const formattedFooterLinks = footerLinks?.map(
    (footerLink: any, i: number) => {
      return {
        onClick: createOnClickFromPopulatedActionButton(footerLink),
        title: footerLink.actionButton,
      };
    }
  );
  return (
    <Footer
      footerLinks={formattedFooterLinks}
      marginAndPadding={marginAndPadding}
      logo={logo}
    />
  );
}

export default LayouterRetailerFooter;
