import { createContext, useState, useEffect, useContext } from "react";
import { authRoute, localStorage, sanityClient as client } from "utilities";
import authenticationContext from "components/Authentication";

const cartContext: any = createContext(null);
export function CartContextProvider({ children }: any) {
  const [cart, setCart] = useState<any>([]);
  const { user }: any = useContext(authenticationContext);
  const body = { cart, email: user?.email };
  const [reloadCart, setReloadCart] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [populatedCart, setPopulatedCart] = useState<any>([]);
  const [buyNowCart, setBuyNowCart] = useState<any>([]);
  const [lastPurchase, setLastPurchase] = useState<any>(null);

  useEffect(() => {
    if (user) {
      (async () => {
        const cart = await fetchCartFromUserJWT(user.JWT);
        setCart(cart);
        setInitialized(true);
      })();
    } else if (localStorage.has("cartBody")) {
      const cartBody: any = localStorage.getObject("cartBody");
      setCart(cartBody?.cart);
      setInitialized(true);
    }
  }, [user]);

  useEffect(() => {
    if (!initialized) return;
    if (user) updateUserDBCart({ ...body, JWT: user.JWT });
    localStorage.set("cartBody", body);
  }, [initialized, cart, user]);

  useEffect(() => {
    getPopulatedCart(cart)
      .then((populatedCart: any) => {
        setPopulatedCart(populatedCart);
      })
      .catch((err: any) => {
        console.error("Error populating cart: ", err);
      });
  }, [cart]);

  return (
    <cartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        reloadCart: reloadCartFunction,
        resetCart,
        populatedCart,
        buyNowCart,
        addToBuyNowCart,
        resetBuyNowCart,
        lastPurchase,
        setLastPurchase,
      }}
    >
      {children}
    </cartContext.Provider>
  );
  function addToCart(item: any) {
    if (!cart) setCart([item]);
    setCart([...cart, item]);
  }
  function removeFromCart(itemID: string) {
    console.log("removing", itemID);
    const newCart = cart.filter(
      (cartItem: any) => cartItem.productID !== itemID
    );
    if (newCart.length)
      setCart(cart.filter((cartItem: any) => cartItem.productID !== itemID));
    else resetCart();
  }
  function reloadCartFunction() {
    setReloadCart(!reloadCart);
  }
  function resetCart() {
    setCart([]);
  }
  async function addToBuyNowCart(item: any) {
    const populatedItem = await getPopulatedCart([item]);
    setBuyNowCart(populatedItem);
  }
  function resetBuyNowCart() {
    setBuyNowCart(null);
  }
  function getPopulatedCart(cart: any) {
    const populatedCart: any = [];
    return new Promise((resolve, reject) => {
      if (cart.length === 0) resolve([]);
      for (let i = 0; i < cart.length; i++) {
        if (!cart[i]?.productID) {
          console.error(
            "Cart has an undefined entire or productID in it. cart:",
            cart
          );
          continue;
        }
        client
          .fetch(
            `*[_id == "${cart[i]?.productID}"]{..., thumbnail{asset->{url}},  previews[]{asset->{url}}}`
          )
          .then((response: any) => {
            if (response[0]?._id)
              populatedCart.push({
                productID: response[0]._id,
                ...response[0],
              });
            if (i === cart.length - 1) {
              resolve(populatedCart);
            }
          })
          .catch((err: any) => {
            reject(err);
          });
      }
    });
  }
}

export default cartContext;

function updateUserDBCart(extendedBody: any) {
  fetch(`${authRoute}/user/updatecart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(extendedBody),
  })
    .then(async (res) => {
      if (!res.ok) return Promise.reject(await res.json());
      return res.json();
    })
    .then(() => {
      console.log("Successfully updated cart: ");
    })
    .catch((err) => {
      console.error("Error updating cart: ", err);
    });
}

function fetchCartFromUserJWT(JWT: string) {
  return new Promise((resolve, reject) => {
    fetch(`${authRoute}/user/getCart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ JWT }),
    })
      .then(async (res) => {
        if (!res.ok) return Promise.reject(await res.json());
        return res.json();
      })
      .then((res) => {
        return resolve(res.cart);
      })
      .catch((err) => {
        console.error("Error fetching cart: ", err);
        return reject(err);
      });
  });
}
