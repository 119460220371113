import { LayouterRetailerLandingScreen } from "components/LandingScreen";
import { LayouterRetailerFunnelItem02 } from "components/FunnelItem02";
import { LayouterRetailerFunnelItem03 } from "components/FunnelItem03";
import { LayouterRetailerFunnelItem04 } from "components/FunnelItem04";
import { LayouterRetailerFunnelItem05 } from "components/FunnelItem05";
import { LayouterRetailerFunnelItem06 } from "components/FunnelItem06";
import { LayouterRetaillerProductDisplay } from "components/ProductDisplay";
import { LayouterRetailerFunnelItem01 } from "components/FunnelItem01";
import { LayouterRetailerMassiveHeader } from "components/MassiveHeader";
import { LayouterRetailerCart } from "components/Cart";
import { LayouterRetailerCheckout } from "components/Checkout";
import LayouterRetailerSignUp from "components/SignUp";
import LayouterRetailerSignIn from "components/SignIn/LayouterRetailerSignIn";
import { LayouterRetailerConfirmation } from "components/Confirmation";
import ContactSupport from "components/ContactSupport";
import { LayouterRetailerProduct } from "components/Product";
import SignOut from "components/SignOut";
import EnterForgottenEmail from "components/EnterForgottenEmail";
import ResetSent from "components/ResetSent";
import EnterNewPassword from "components/EnterNewPassword";
import PasswordResetConfirmation from "components/PasswordResetConfirmation";

const sectionMap = [
  {
    section: "landingScreenSection",
    component: (props: any) => {
      return (
        <LayouterRetailerLandingScreen
          {...{ ...props, key: Math.floor(Math.random() * 100) }}
        />
      );
    },
  },
  {
    section: "funnelItem01Section",
    component: LayouterRetailerFunnelItem01,
  },
  {
    section: "funnelItem02Section",
    component: LayouterRetailerFunnelItem02,
  },
  {
    section: "funnelItem03Section",
    component: LayouterRetailerFunnelItem03,
  },
  {
    section: "funnelItem04Section",
    component: LayouterRetailerFunnelItem04,
  },
  {
    section: "funnelItem05Section",
    component: LayouterRetailerFunnelItem05,
  },
  {
    section: "funnelItem06Section",
    component: LayouterRetailerFunnelItem06,
  },
  {
    section: "shopDisplaySection",
    component: () => {
      //@ts-ignore
      return <div>This is the shop section</div>;
    },
  },
  {
    section: "productDisplaySection",
    component: (props: any) => {
      return (
        <LayouterRetaillerProductDisplay
          {...{ ...props, key: Math.floor(Math.random() * 100) }}
        />
      );
    },
  },
  {
    section: "massiveHeaderSection",
    component: LayouterRetailerMassiveHeader,
  },
  {
    section: "productSection",
    component: LayouterRetailerProduct,
  },
  {
    section: "cartSection",
    component: LayouterRetailerCart,
  },
  {
    section: "checkoutSection",
    component: LayouterRetailerCheckout,
  },
  {
    section: "signUpSection",
    component: LayouterRetailerSignUp,
  },
  {
    section: "signInSection",
    component: LayouterRetailerSignIn,
  },
  {
    section: "confirmationSection",
    component: LayouterRetailerConfirmation,
  },
  {
    section: "contactSupportSection",
    component: (props: any) => {
      return (
        <ContactSupport
          firstComp={props.firstComp}
          sectionMarginAndPadding={props.sectionMarginAndPadding}
        />
      );
    },
  },
  {
    section: "signOutSection",
    component: SignOut,
  },
  {
    section: "enterForgottenEmailSection",
    component: EnterForgottenEmail,
  },
  {
    section: "resetSentSection",
    component: ResetSent,
  },
  {
    section: "enterNewPasswordSection",
    component: EnterNewPassword,
  },
  {
    section: "passwordResetConfirmationSection",
    component: PasswordResetConfirmation,
  },
];

export default sectionMap;
