import { useEffect, useState } from "react";
import "./resetSent.scss";

function ResetSent({ firstComp }: any) {
  const [email, setEmail] = useState<any>();

  useEffect(() => {
    //Get email from url variable
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    setEmail(email);
  }, []);
  return email ? (
    <div
      className={`retailer-ui-container reset-sent-container ${
        firstComp ? "first-comp-margin" : ""
      }`}
    >
      <h4>A password reset link has been sent to {email}</h4>
    </div>
  ) : (
    <></>
  );
}

export default ResetSent;
