import { useState, useEffect, useRef } from "react";
import "./style.scss";
import FilterType from "./FilterType";
import AnimateHeight from "react-animate-height";
import { useMediaQuery } from "react-responsive";
export default function Filter({ formatedFilter, updateFilter }: FilterType) {
  console.log("formatedFilter:", formatedFilter);
  const [extend, setExtend] = useState(true);
  const [extendFilterByPrice, setExtendFilterByPrice] = useState(false);
  const [extendFilterByColor, setExtendFilterByColor] = useState(false);
  const [extendFilterByStyle, setExtendFilterByStyle] = useState(false);
  const [extendFilterBySize, setExtendFilterBySize] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [minFocouser, setMinFocouser] = useState<boolean>(false);
  const [maxFocouser, setMaxFocouser] = useState<boolean>(false);

  const minRef = useRef(null);
  const maxRef = useRef(null);

  useEffect(() => {
    //@ts-ignore
    if (!minRef?.current?.focus) return;
    console.log("Refocousing");
    //@ts-ignore Terrible hack
    setTimeout(() => minRef.current.focus(), 100);
  }, [minFocouser]);

  useEffect(() => {
    //@ts-ignore
    if (!maxRef?.current?.focus) return;
    //@ts-ignore
    setTimeout(() => maxRef.current.focus(), 100);
  }, [maxFocouser]);

  const activeFilter = useActiveFilter({
    extendFilterByPrice,
    extendFilterByColor,
    extendFilterByStyle,
  });

  useEffect(() => {
    setExtend(
      extendFilterByPrice || extendFilterByColor || extendFilterByStyle
    );
  }, [extendFilterByPrice, extendFilterByColor, extendFilterByStyle]);

  return (
    <div
      className={`retailer-ui-container filter-container ${
        isMobile ? "mobile" : ""
      }`}
    >
      <div className="inner-filter-container">
        <button
          className="filter-button"
          onClick={() => {
            setExtendFilterByPrice(false);
            setExtendFilterByStyle(false);
            setExtendFilterByColor(!extendFilterByColor);
          }}
        >
          <p className="nav-link">Filter By Color</p>
          <img
            className="filter-category-toggler"
            src={`${
              extendFilterByColor ? "/icon/less.svg" : "/icon/dropdown.svg"
            }`}
          />
        </button>
        <AnimateHeight
          height={extend && isMobile && extendFilterByColor ? "auto" : 0}
        >
          <ExtendedFiltersMobile />
        </AnimateHeight>

        <button
          className="filter-button"
          onClick={() => {
            setExtendFilterByColor(false);
            setExtendFilterByStyle(false);
            setExtendFilterByPrice(!extendFilterByPrice);
          }}
        >
          <p className="nav-link">Filter By Price</p>
          <img
            className="filter-category-toggler"
            src={`${
              extendFilterByPrice ? "/icon/less.svg" : "/icon/dropdown.svg"
            }`}
          />
        </button>
        <AnimateHeight
          height={extend && isMobile && extendFilterByPrice ? "auto" : 0}
        >
          <ExtendedFiltersMobile />
        </AnimateHeight>

        <button
          className="filter-button"
          onClick={() => {
            setExtendFilterByColor(false);
            setExtendFilterByPrice(false);
            setExtendFilterByStyle(!extendFilterByStyle);
          }}
        >
          <p className="nav-link">Filter By Size</p>
          <img
            className="filter-category-toggler"
            src={`${
              extendFilterByStyle ? "/icon/less.svg" : "/icon/dropdown.svg"
            }`}
          />
        </button>
        <AnimateHeight
          height={extend && isMobile && extendFilterByStyle ? "auto" : 0}
        >
          <ExtendedFiltersMobile />
        </AnimateHeight>
      </div>
      <AnimateHeight height={extend && !isMobile ? "auto" : 0}>
        <div className="extended-filter-container">
          {activeFilter === "price" ? (
            <PriceFilter updateFilter={updateFilter} filter={formatedFilter} />
          ) : activeFilter === "color" ? (
            <ColorFilter updateFilter={updateFilter} />
          ) : activeFilter === "style" ? (
            <SizeFilter updateFilter={updateFilter} />
          ) : (
            <>
              <br /> <br />
            </>
          )}
        </div>
      </AnimateHeight>
    </div>
  );

  function ExtendedFiltersMobile() {
    // Massive work in progress. The name of this function will have to change and the structure of this function will have to change.
    return (
      <>
        {extendFilterByPrice ? (
          <ExtendedFilterContainer>
            <PriceFilter updateFilter={updateFilter} filter={formatedFilter} />
          </ExtendedFilterContainer>
        ) : extendFilterByColor ? (
          <ExtendedFilterContainer>
            <ColorFilter updateFilter={updateFilter} />
          </ExtendedFilterContainer>
        ) : extendFilterByStyle ? (
          <ExtendedFilterContainer>
            <SizeFilter updateFilter={updateFilter} />
          </ExtendedFilterContainer>
        ) : (
          <>
            <br /> <br />
            <br /> <br />
          </>
        )}
      </>
    );
  }

  function ExtendedFilterContainer({ children }: any) {
    return (
      <div
        className={`extended-filter-container ${
          extendFilterByPrice
            ? "price_filter"
            : extendFilterByColor
            ? "color_filter"
            : "size_filter"
        }`}
      >
        {children}
      </div>
    );
  }

  function PriceFilter({ updateFilter, filter }: any) {
    return (
      <>
        <section className="input-container">
          <label className="input-label nav-link" htmlFor="min-price-input">
            Min Price
          </label>
          <input
            className="input-field"
            type="number"
            id="min-price-input"
            defaultValue={getMinMax(filter).min}
            onChange={(e) => {
              updateFilter(
                { min: e.target.value, max: getMinMax(filter).max },
                { action: "update", type: "priceFilter" }
              );
              setMinFocouser(!minFocouser);
            }}
            ref={minRef}
          />
        </section>
        <section className="input-container">
          <label className="input-label nav-link" htmlFor="min-price-input">
            Max Price
          </label>
          <input
            className="input-field"
            type="number"
            id="min-price-input"
            defaultValue={getMinMax(filter).max}
            onChange={(e) => {
              updateFilter(
                { min: getMinMax(filter).min, max: e.target.value },
                { action: "update", type: "priceFilter" }
              );
              setMaxFocouser(!maxFocouser);
            }}
            ref={maxRef}
          />
        </section>
      </>
    );
  }

  function ColorFilter({ updateFilter }: any) {
    return (
      <>
        <CheckboxInput
          Label={
            <label
              className="input-label color-label nav-link red"
              htmlFor="red-filter-input"
            >
              Red
            </label>
          }
          Input={
            <input
              className="input-field"
              type="checkbox"
              defaultChecked={isFilterEnabled("red")}
              onClick={() => {
                updateFilter("red", {
                  action: isFilterEnabled("red") ? "remove" : "add",
                  type: "colorFilter",
                });
              }}
            ></input>
          }
        />
        <CheckboxInput
          Label={
            <label
              className="input-label nav-link color-label purple"
              htmlFor="Purple-filter-input"
              onClick={() => {
                updateFilter("purple", {
                  action: isFilterEnabled("purple") ? "remove" : "add",
                  type: "colorFilter",
                });
              }}
            >
              Purple
            </label>
          }
          Input={
            <input
              className="input-field mob_mt"
              type="checkbox"
              id="Purple-filter-input"
              defaultChecked={isFilterEnabled("purple")}
              onClick={() => {
                updateFilter("purple", {
                  action: isFilterEnabled("purple") ? "remove" : "add",
                  type: "colorFilter",
                });
              }}
            ></input>
          }
        />
        <CheckboxInput
          className="mob_mt"
          Label={
            <label
              className="input-label nav-link color-label black"
              htmlFor="black-filter-input"
            >
              Black
            </label>
          }
          Input={
            <input
              className="input-field"
              type="checkbox"
              id="black-filter-input"
              defaultChecked={isFilterEnabled("black")}
              onClick={() => {
                updateFilter("black", {
                  action: isFilterEnabled("black") ? "remove" : "add",
                  type: "colorFilter",
                });
              }}
            ></input>
          }
        />
        <CheckboxInput
          className="mob_mt"
          Label={
            <label
              className="input-label nav-link color-label white"
              htmlFor="White-filter-input"
            >
              White
            </label>
          }
          Input={
            <input
              className="input-field"
              type="checkbox"
              id="White-filter-input"
              defaultChecked={isFilterEnabled("white")}
              onClick={() => {
                updateFilter("white", {
                  action: isFilterEnabled("white") ? "remove" : "add",
                  type: "colorFilter",
                });
              }}
            ></input>
          }
        />
        <CheckboxInput
          className="mob_mt"
          Label={
            <label
              className="input-label nav-link color-label orange"
              htmlFor="orange-filter-input"
            >
              Orange
            </label>
          }
          Input={
            <input
              className="input-field"
              type="checkbox"
              id="orange-filter-input"
              defaultChecked={isFilterEnabled("orange")}
              onClick={() => {
                updateFilter("orange", {
                  action: isFilterEnabled("orange") ? "remove" : "add",
                  type: "colorFilter",
                });
              }}
            ></input>
          }
        />
        <button className="input-container button-outline">Submit</button>
      </>
    );
  }

  function SizeFilter({ updateFilter }: any) {
    return (
      <>
        <CheckboxInput
          Label={
            <label className="input-label nav-link" htmlFor="red-filter-input">
              S
            </label>
          }
          Input={
            <input
              className="input-field"
              type="checkbox"
              defaultChecked={isFilterEnabled("s")}
              onClick={() => {
                updateFilter("s", {
                  action: isFilterEnabled("s") ? "remove" : "add",
                  type: "categoryFilter",
                });
              }}
            ></input>
          }
        />
        <CheckboxInput
          Label={
            <label className="input-label nav-link" htmlFor="Blue-filter-input">
              M
            </label>
          }
          Input={
            <input
              className="input-field"
              type="checkbox"
              id="Blue-filter-input"
              defaultChecked={isFilterEnabled("m")}
              onClick={() => {
                updateFilter("m", {
                  action: isFilterEnabled("m") ? "remove" : "add",
                  type: "categoryFilter",
                });
              }}
            ></input>
          }
        />
        <CheckboxInput
          Label={
            <label
              className="input-label nav-link"
              htmlFor="green-filter-input"
            >
              L
            </label>
          }
          Input={
            <input
              className="input-field"
              type="checkbox"
              id="green-filter-input"
              defaultChecked={isFilterEnabled("l")}
              onClick={() => {
                updateFilter("l", {
                  action: isFilterEnabled("l") ? "remove" : "add",
                  type: "categoryFilter",
                });
              }}
            ></input>
          }
          className="mob_mt"
        />
        <CheckboxInput
          Label={
            <label
              className="input-label nav-link"
              htmlFor="green-filter-input"
            >
              XL
            </label>
          }
          Input={
            <input
              className="input-field"
              type="checkbox"
              id="green-filter-input"
              defaultChecked={isFilterEnabled("xl")}
              onClick={() => {
                updateFilter("xl", {
                  action: isFilterEnabled("xl") ? "remove" : "add",
                  type: "categoryFilter",
                });
              }}
            ></input>
          }
          className="mob_mt"
        />
        <button className="input-container button-outline">Submit</button>
      </>
    );
  }

  function getMinMax(filter: any) {
    const indexOfPriceFilter: number = formatedFilter.findIndex(
      (filter: any) => filter.type === "priceFilter"
    );
    if (indexOfPriceFilter === -1) {
    }
    const min = formatedFilter[indexOfPriceFilter]?.min;
    const max = formatedFilter[indexOfPriceFilter]?.max;
    return { min, max };
  }

  function isFilterEnabled(filter: string) {
    for (
      let i = 0;
      i < (formatedFilter?.length ? formatedFilter.length : 0);
      i++
    ) {
      const currentFilter = formatedFilter ? formatedFilter[i] : null;
      if (!currentFilter) continue;

      //TODO: Check if currentFilter is a price type filter

      //Checks if currentFilter is a category type filter
      for (let j = 0; j < (currentFilter?.choices?.length || 0); j++) {
        const currentChoice = currentFilter?.choices
          ? currentFilter?.choices[j]
          : null;
        if (!currentChoice) continue;
        const isEnabled = currentChoice?.enable;
        if (!isEnabled) continue;
        if (currentChoice?.category === filter) return true;
      }
    }

    return false;
  }

  function CheckboxInput({ Label, Input, className }: any) {
    return isMobile ? (
      <section className={`input-container flex-row ${className}`}>
        {Input}
        {Label}
      </section>
    ) : (
      <section className={`input-container flex-row ${className}`}>
        {Label}
        {Input}
      </section>
    );
  }
}

const useActiveFilter = (filterMap: any) => {
  const [activeFilter, setActiveFilter] = useState("none");
  useEffect(() => {
    setActiveFilter(
      (() => {
        let changeTo = "none2";
        if (filterMap.extendFilterByPrice) {
          changeTo = "price";
        } else if (filterMap.extendFilterByColor) {
          changeTo = "color";
        } else if (filterMap.extendFilterByStyle) {
          changeTo = "style";
        }
        return changeTo;
      })()
    );
  }, [filterMap]);
  return activeFilter;
};
