import "./style.scss";
import funnelItem02 from "./funnelItem02Type";
import { createMarginPaddingStyles } from "components/Utilities";
export default function FunnelItem02({
  displayImage,
  customMarginPadding,
  title,
  subtitle,
  actionText,
  onAction,
}: funnelItem02) {
  return (
    <div
      className="funnel-item-02-container"
      style={{
        /* @ts-ignore */
        ...createMarginPaddingStyles(customMarginPadding),
      }}
    >
      <section>
        <div className="retailer-ui-container flex flex-col">
          <div className="center-content">
            <h3>{title}</h3>
            <p>{subtitle}</p>
            {actionText ? (
              <a onClick={localOnAction} className="nav-link">
                {actionText}
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
      <img
        alt="background to site section"
        src={displayImage ? displayImage : ""}
      />
    </div>
  );

  function localOnAction() {
    onAction ? onAction() : console.log("Click dose nothing");
  }
}
