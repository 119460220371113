import "./cart.scss";
const placeholderProduct = "/dev/01.png";
export default function DesktopCart({
  excludeCheckout,
  excludePrice,
  excludeNotes,
  cart,
  onRemoveFromCart,
  onCheckout,
  shipping,
  subTotal,
  taxAmount,
  total,
  stripePercentage,
  shippingStatus = "unknown",
  capitalizeAndAddSpaces,
}: any) {
  return (
    <div className={`dsc-container`}>
      <section>
        <label>Product</label>
        <label>Price</label>
        <label>Quantity</label>
        <label>Total</label>
      </section>
      <hr />

      {cart ? (
        cart.map((cartItem: any, i: number) => {
          return <CartItem key={i} cartItem={cartItem} />;
        })
      ) : (
        <></>
      )}

      <hr />
      {excludeNotes && excludePrice && excludeCheckout ? (
        <></>
      ) : (
        <section className="final-details-row">
          {!excludeNotes ? (
            <section className="note">
              <label>Add Note To Order</label>
              <textarea className="add_note"></textarea>
            </section>
          ) : (
            <></>
          )}
          {!excludePrice ? (
            <section className="prices_container">
              <div>
                <p>Subtotal</p>
                <p>${subTotal}</p>
              </div>
              <div>
                <p>Estimated Shipping</p>
                <p>
                  {shippingStatus !== "success" && shippingStatus !== "unknown"
                    ? capitalizeAndAddSpaces(shippingStatus)
                    : `$${shipping}` || "$0"}
                </p>
              </div>
              <div>
                <p>Taxes</p>
                <p>${taxAmount}</p>
              </div>
              {!excludePrice ? (
                <h4>
                  {shippingStatus !== "success" && shippingStatus !== "unknown"
                    ? capitalizeAndAddSpaces(shippingStatus)
                    : `Total $${total}` || "Total $0"}
                </h4>
              ) : (
                <></>
              )}
              {!excludeCheckout ? (
                <button onClick={onCheckout}>CHECKOUT</button>
              ) : (
                <></>
              )}
            </section>
          ) : (
            <></>
          )}
        </section>
      )}
    </div>
  );

  function CartItem({ cartItem }: any) {
    const price = roundToTwoDecimalPlaces(
      (cartItem.price || 0) * ((stripePercentage || 0) + 1)
    );
    return (
      <section className="product_cart_row">
        <RemoveButton
          onRemoveFromCart={() => {
            onRemoveFromCart(cartItem?.productID);
          }}
        />
        <img
          style={{
            backgroundImage: `url(${
              cartItem.thumbnail?.asset?.url || placeholderProduct
            })`,
          }}
        />
        <div className="title_size_container">
          <p>{cartItem.title}</p>
          <p>Size</p>
        </div>
        <div className="price_container">
          <p>${price}</p>
        </div>
        <div className="quantity_container">
          <p>1</p>
        </div>
        <div className="total_container">
          <p>${price}</p>
        </div>
      </section>
    );
  }
}

function RemoveButton({ onRemoveFromCart }: any) {
  return (
    <button
      className="remove_button"
      onClick={() => {
        onRemoveFromCart();
      }}
    >
      <img alt="delete" src="/icon/delete_white.svg" />
    </button>
  );
}
function roundToTwoDecimalPlaces(num: any) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
