import { createContext, useEffect, useState, useContext } from "react";
//@ts-ignore
import { layouterContext } from "layouter-tools";

export const settingsContext = createContext<any>({});

export default function SettingsProvider({ children }: any) {
  //@ts-ignore
  const { client } = useContext(layouterContext) || {};
  const [settings, setSettings] = useState<any>(null);
  const [homePageSlug, setHomePageSlug] = useState<any>(null);
  useEffect(() => {
    if (!client) return;
    const settingsQuery = `*[_type == "settings"][0]{..., siteLogo{..., asset->}, altLogo{..., asset->}, forgotPassword{..., pageChange->},  enterNewPassword{..., pageChange->}, resetPasswordConfirmation{..., pageChange->}, restPasswordSent{..., pageChange->}, checkoutAction{..., pageChange->}, purchaseConfirmationAction{..., pageChange->}}`;
    client
      .fetch(settingsQuery)
      .then((settings: any) => {
        setSettings(settings);
      })
      .catch(console.error);
    const homePageSlugQuery = `*[_id == "homePage"]{"slug": homePage->{slug{current}}}`;
    client
      .fetch(homePageSlugQuery)
      .then((homePageSlug: any) => {
        setHomePageSlug(homePageSlug[0]?.slug?.slug?.current);
      })
      .catch(console.error);
  }, [client]);

  return (
    <settingsContext.Provider value={{ settings, homePageSlug }}>
      {children}
    </settingsContext.Provider>
  );
}
