export function getSubtotalFromCart(cart: any): number {
  if (!cart) return 0;
  let subtotal = 0;
  cart.forEach((cartItem: any) => {
    subtotal += cartItem.price;
  });
  return roundToTwoDecimalPlaces(subtotal);
}

export function getSubtotalWithShipping(
  subtotal: number,
  shipping: number = 0
): number {
  return roundToTwoDecimalPlaces(subtotal + shipping);
}

export function calculateTaxes(
  subtotalWithShipping: number,
  tax: number
): number {
  return roundToTwoDecimalPlaces(tax * subtotalWithShipping);
}

export function roundToTwoDecimalPlaces(num: any) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
