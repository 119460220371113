import { useContext, useEffect, useState } from "react";
import FunnelItem04 from "./FunnelItem04";
import { changeURLContext } from "components/Utilities";
import { getURLFromSanityRefrence } from "components/Utilities";
//@ts-ignore
import { layouterContext } from "layouter-tools";
import { createMarginPaddingStyles } from "components/Utilities";
import { settingsContext } from "components/Settings";

function LayouterRetailerFunnelItem04(props: any) {
  const {
    displayImage,
    title,
    subtitle,
    backgroundColor,
    textColor,
    sectionMarginAndPadding,
  } = props;
  const { client }: any = useContext(layouterContext) || {};
  const { settings }: any = useContext(settingsContext) || {};
  const [backgroundImage, setBackgroundImage] = useState<any>(null);

  const { handleActionButton } = useContext(changeURLContext);
  const customMarginPaddingStyle = createMarginPaddingStyles(
    sectionMarginAndPadding
  );

  useEffect(() => {
    (async () => {
      setBackgroundImage(await getURLFromSanityRefrence(displayImage, client));
    })();
  }, [displayImage]);
  return (
    <FunnelItem04
      title={title}
      onAction={() => {
        handleActionButton(props);
      }}
      displayImage={backgroundImage}
      subtitle={subtitle}
      backgroundColor={backgroundColor}
      textColor={textColor}
      customMarginPadding={customMarginPaddingStyle}
      logo={settings?.altLogo?.asset?.url}
    />
  );
}

export default LayouterRetailerFunnelItem04;
