import "./style.scss";
import productCard from "./productCardType";
export default function ProductCard({
  displayImage,
  title,
  subtitle,
  onClick,
  price,
}: productCard) {
  return (
    <div
      className="product-card-container logo"
      onClick={onClick || defaultOnClick}
    >
      <img className="thumbail" src={displayImage} alt={title} />
      <img className="logo" src="/icon/logo.png" alt="logo" />
      <h6>{title}</h6>
      <p>{price}</p>
    </div>
  );
}

function defaultOnClick(e: any) {}
