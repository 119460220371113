import { useState, useEffect } from "react";
import { sanityClient as client } from "utilities";

//This file differs from useFormatedProducts in that it is used gets a products id from the URL and returns its formatedProduct

export default function useProduct() {
  const [rawProduct, setRawProduct] = useState({});
  const [product, setProduct] = useState({});

  //TODO: get shipping price from settings
  const shipingPrice = 20;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productID = urlParams.get("productid");
    client
      .fetch(
        `*[_id == "${productID}"]{..., thumbnail{asset->{url}},  previews[]{asset->{url}}}`
      )
      .then((response: any) => {
        setRawProduct(response[0]);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const formatedProduct = getFormatedProduct(rawProduct);
    setProduct(formatedProduct);
  }, [rawProduct]);

  return product;

  //TODO: refactor this function to antoher file so that it may be properly reused
  function getFormatedProduct(rawProduct: any) {
    let product = {
      title: rawProduct?.title,
      subtitle: rawProduct?.subtitle,
      price: rawProduct?.price,
      shipingPrice,
      productImage: rawProduct?.thumbnail?.asset?.url,
      previews: getFormatedPreviews(),
      categories: rawProduct?.categories,
      productID: rawProduct?._id,
    };

    function getFormatedPreviews() {
      let rawPreviews: any = rawProduct?.previews || [];
      let previews: any = [];

      for (let i = 0; i < rawPreviews.length; i++) {
        previews.push(rawPreviews[i]?.asset?.url);
      }
      return previews;
    }

    return product;
  }
}
