import React from "react";
import "./style.scss";
import massiveHeaderType from "./MassiveHeaderType";
import { SFW } from "Config";

export default function MassiveHeader({
  title,
  backgroundImage,
  textColor,
  firstComp,
}: massiveHeaderType) {
  return (
    <div
      className={`massive-header-container ${
        firstComp ? "smaller-first-comp-margin" : ""
      }`}
    >
      <img
        className="background-image"
        src={
          SFW
            ? "/dev/building.png"
            : backgroundImage
            ? (backgroundImage as string)
            : "/dev/two-pice-bg.jpg"
        }
      />
      <div className="center-text-container">
        <h5
          style={{
            color: textColor ? textColor : "#ffffff",
          }}
        >
          {title ? title : "Two Pices"}
        </h5>
      </div>
    </div>
  );
}
