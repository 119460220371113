import React, { useEffect, useState, useContext } from "react";
//@ts-ignore
import { layouterContext } from "layouter-tools";
import { changeURLContext } from "components/Utilities";
import Film from "components/Film";
import LandingScreen from "./LandingScreen";
import "./style.scss";

export default function View(props: any) {
  //@ts-ignore
  const { client, routeStore } = useContext(layouterContext) || {};
  const { changeURL } = useContext(changeURLContext);
  const [videoURL, setVideoURL] = useState();
  const [pageURL, setPageURL] = useState();

  useEffect(() => {
    if (videoURL) return;
    const query = `*[_id=="${props?.videoBackground?.asset?._ref}"][0]{url}`;
    client.fetch(query).then((queriedURL: any) => {
      setVideoURL(queriedURL.url);
    });
  }, [videoURL]);

  useEffect(() => {
    if (pageURL || !props.displayActionButton) return;
    if (!props.pageChange) return setPageURL(props.url);
    const query = `*[_id=="${props.pageChange._ref}"][0]{"slug": slug.current}`;
    client.fetch(query).then((queriedURL: any) => {
      setPageURL(queriedURL.slug);
    });
  }, [pageURL]);

  return (
    <LandingScreen
      className={props?.firstComp ? "first-comp-margin" : ""}
      onAction={() => {
        changeURL(props?.actionButtonAction, pageURL, props?.url);
      }}
      videoURL={videoURL}
      actionSlug={pageURL}
      url={props?.url}
      actionText={props?.actionButton}
      textColor={props?.textColor}
      BGOpacity={props.backgroundOpacityPercentage}
      BGColor={props?.backgroundOpacityColor}
      title={props?.title}
      subtitle={props?.subtitle}
    />
  );
}
