import { useContext, useEffect, useState } from "react";
import FunnelItem06 from "./FunnelItem06";
import { getURLFromSanityRefrence } from "components/Utilities";
//@ts-ignore
import { layouterContext } from "layouter-tools";
import { createMarginPaddingStyles } from "components/Utilities";

export default function LayouterRetailerFunnelItem06({
  title,
  description,
  firstComp,
  displayImage,
  textColor,
  backgroundColor,
  sectionMarginAndPadding,
  ...props
}: any) {
  const [backgroundImage, setBackgroundImage] = useState<any>(null);
  const { client }: any = useContext(layouterContext) || {};
  const customMarginPadding = createMarginPaddingStyles(
    sectionMarginAndPadding
  );

  useEffect(() => {
    (async () => {
      setBackgroundImage(await getURLFromSanityRefrence(displayImage, client));
    })();
  }, [displayImage]);

  console.log("LayouterRetailerFunnelItem06 props", props);
  return (
    <FunnelItem06
      title={title}
      description={description}
      firstComp={firstComp}
      displayImage={backgroundImage}
      textColor={textColor}
      backgroundColor={backgroundColor}
      customMarginPadding={customMarginPadding}
    />
  );
}
