import { useContext } from "react";
import EnterForgottenEmail from "./EnterForgottenEmail";
import { authRoute } from "utilities";
import { settingsContext } from "components/Settings";
import { useFlash } from "components/FlashMessage";
import { changeURLContext } from "components/Utilities";

function LayouterRetailerEnterForgottenEmail({ firstComp }: any) {
  const { settings }: any = useContext(settingsContext);
  const { changeURL } = useContext(changeURLContext);
  const newPasswordPageSlug =
    settings?.enterNewPassword?.pageChange?.slug?.current;
  const origin = window.location.origin;
  const { flashRequest } = useFlash();
  return (
    <>
      <EnterForgottenEmail firstComp={firstComp} onSendEmail={onSendEmail} />
    </>
  );

  async function onSendEmail(email: string) {
    if (!newPasswordPageSlug)
      return (() => {
        flashRequest({
          message: "Error on our end. newPasswordPageSlug is not defined",
          type: "error",
          title: "Error",
        });
      })();
    fetch(`${authRoute}/user/sendPasswordResetEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        newPasswordPageSlug,
        origin,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const resetSentPageSlug =
          settings?.restPasswordSent?.pageChange?.slug?.current;
        if (!resetSentPageSlug)
          return (() => {
            flashRequest({
              message: "Error on our end. restSentPageSlug is not defined",
              type: "error",
              title: "Error",
            });
          })();
        // Redirect to email confirmation page here
        changeURL("changePage", `${resetSentPageSlug}?email=${email}`, "");
      })
      .catch((err) => {
        console.error(`here and`, err);
      });
  }
}

export default LayouterRetailerEnterForgottenEmail;
