import { localStorage } from "utilities";
export function validateEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(password: string) {
  return password.length > 7;
}

export function validateName(name: string) {
  return name.length > 1;
}

export function getSignUpValidation(user: {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  rememberMe: boolean;
}) {
  if (!validateEmail(user["email"])) {
    return { isValid: false, message: "Email is invalid" };
  } else if (!validatePassword(user["password"])) {
    return { isValid: false, message: "Password is invalid" };
  } else if (!validateName(user["firstName"])) {
    return { isValid: false, message: "First name is invalid" };
  } else if (!validateName(user["lastName"])) {
    return { isValid: false, message: "Last name is invalid" };
  } else {
    return { isValid: true };
  }
}

export function validateUserSignIn(user: {
  email: string;
  password: string;
  rememberMe: boolean;
}) {
  if (!validateEmail(user["email"])) {
    return { isValid: false, message: "Email is invalid" };
  } else if (!validatePassword(user["password"])) {
    return { isValid: false, message: "Password is invalid" };
  } else {
    return { isValid: true };
  }
}

export function getJWT() {
  return localStorage.getObject("userJWT");
}
