import { useContext, useEffect } from "react";
import Cart from "./Cart";
import cartContext from "./cartContext";
import { settingsContext } from "components/Settings";
import { changeURLContext } from "components/Utilities";
import { pricing } from "components/Utilities";

function LayouterRetailerCart({ firstComp, title, ...rest }: any) {
  const { removeFromCart, populatedCart }: any = useContext(cartContext);
  const { settings }: any = useContext(settingsContext);
  const { handleActionButton } = useContext(changeURLContext);
  const tax = 0.2;
  const stripePercentage = 0.03;
  const ESTIMATED_SHIPPING = 20;
  const shipping = pricing.roundToTwoDecimalPlaces(
    ((settings?.automaticShippingServiceFee || 0) + ESTIMATED_SHIPPING) *
      (1 + stripePercentage)
  );

  const subTotal =
    pricing.getSubtotalFromCart(populatedCart) * (1 + stripePercentage);

  const subTotalWithShipping = pricing.getSubtotalWithShipping(
    subTotal,
    shipping
  );
  const taxAmount = pricing.calculateTaxes(subTotalWithShipping, tax);
  const total = pricing.roundToTwoDecimalPlaces(
    subTotalWithShipping + taxAmount
  );

  return (
    <Cart
      firstComp={firstComp}
      cart={populatedCart}
      onRemoveFromCart={removeFromCart}
      onCheckout={onCheckout}
      title={title}
      shipping={shipping}
      subTotal={subTotal}
      subTotalWithShipping={subTotalWithShipping}
      taxAmount={taxAmount}
      total={total}
      stripePercentage={stripePercentage}
    />
  );

  function onCheckout() {
    if (!settings?.checkoutAction?.pageChange) return;
    handleActionButton(settings.checkoutAction);
  }
}

export default LayouterRetailerCart;

function RemoveButton({ onRemoveFromCart }: any) {
  return (
    <button
      className="remove_button"
      onClick={() => {
        onRemoveFromCart();
      }}
    >
      <img alt="delete" src="/icon/delete_white.svg" />
    </button>
  );
}
