import React from "react";
import { createMarginPaddingStyles } from "components/Utilities";
import "./contactSupport.scss";
export default function ContactSupport(props: any) {
  return (
    <div
      className={`contact-support-container retailer-ui-container ${
        props.firstComp ? "first-comp" : ""
      }`}
      style={{
        /* @ts-ignore */
        ...createMarginPaddingStyles(props.sectionMarginAndPadding),
      }}
    >
      <h4>Customer Support</h4>
      <p>
        Were sorry to your experiencing an issue. Please let us know what you
        how we may help you
      </p>
      <label>Email</label>
      <input type="text" />
      <label>Order Confirmation Code (Optional)</label>
      <input type="text" />
      <label>Message</label>
      <textarea />
      <button>ASK SUPPORT</button>
    </div>
  );
}
