import { useContext } from "react";
import { cartContext } from "components/Cart";
import authenticationContext from "components/Authentication";
import SignOut from "./SignOut";

export default function LayouterRetailerSignOut({ firstComp }: any) {
  const { setUser }: any = useContext(authenticationContext);
  const { resetCart }: any = useContext(cartContext);

  return (
    <SignOut className={`${firstComp ? "first-comp" : ""}`} signOut={signOut} />
  );

  function signOut() {
    //TODO: implement rest data function
    window.localStorage.removeItem("userJWT");
    window.localStorage.removeItem("cartBody");
    resetCart();
    setUser(null);
  }
}
