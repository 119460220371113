/* This object is a wraper for local storage.
It is needed because using the defalt local storage dose not format the data compleatly conviently and 
can easily get errors */
const localStorage = {
  getObject: (key: string): object | null | any[] => {
    if (!window?.localStorage?.getItem) return null;
    const rawStorage = window.localStorage.getItem(key);
    if (!rawStorage) return null;
    const object = JSON.parse(rawStorage);
    return object ? object : null;
  },
  set: (key: string, value: object | string) => {
    if (!window?.localStorage?.setItem) return;
    //@ts-ignore
    let valueBuffer: string = JSON.stringify(value);
    window.localStorage.setItem(key, valueBuffer);
  },
  has: (key: string): boolean => {
    if (!window?.localStorage?.getItem) return false;
    const rawStorage = window.localStorage.getItem(key);
    return rawStorage ? true : false;
  },
  remove: (key: string) => {
    if (!window?.localStorage?.removeItem) return;
    window.localStorage.removeItem(key);
  },
};

export default localStorage;
