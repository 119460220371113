import "./style.scss";
import { SFW } from "Config";

export default function View({ marginPaddingStyles }: any) {
  return (
    <div
      className="funnel-item-01-container"
      style={marginPaddingStyles ? { ...marginPaddingStyles } : {}}
    >
      <div className="retailer-ui-container">
        <div className="page_content_wrap">
          <div className="content_wrap">
            <div className="content">
              <span className="content-container">
                <img
                  className="content-image"
                  src={SFW ? "/dev/building.png" : "/images/slider_1_bg_1.jpg"}
                />
                <span className="inner-content-container">
                  <span className="strip"></span>
                  <p className="text-content">
                    <span className="title-content">
                      Luxury
                      <br />
                      Swim
                      <br />
                      Wear
                      <br />
                    </span>
                    <a className="link-item">BROWSE</a>
                  </p>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
