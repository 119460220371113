import React from "react";
import { useMediaQuery } from "react-responsive";
import "./cart.scss";
import DesktopCart from "./DesktopCart";
import MobileCart from "./MobileCart";
export default function CartCore(props: any) {
  const { title } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
  return (
    <>
      <h4>{title}</h4>{" "}
      {isMobile ? <MobileCart {...props} /> : <DesktopCart {...props} />}
    </>
  );
}
