import "./style.scss";
import Film from "components/Film";
import funnelItem03 from "./funnelItem03Type";
import { createMarginPaddingStyles } from "components/Utilities";

export default function FunnelItem03({
  BGURL,
  BGOpacity,
  BGColor,
  actionText,
  onAction,
  title,
  subtitle,
  customMarginPadding,
}: funnelItem03) {
  return (
    <div
      className="funnel-item-03-container"
      style={{
        ...createMarginPaddingStyles(customMarginPadding),
      }}
    >
      <div className="blur">
        <img src={BGURL ? BGURL : ""} alt="backgound display" />
        <Film backgroundColor={BGColor} opacity={BGOpacity} zIndex={1} />
      </div>

      <div className="retailer-ui-container">
        <section>
          <h2>{title}</h2>
          <p>{subtitle}</p>
          {actionText ? (
            <button onClick={curriedOnAction}>{actionText}</button>
          ) : (
            <></>
          )}
        </section>
      </div>
    </div>
  );

  function curriedOnAction() {
    return onAction
      ? () => {
          onAction();
        }
      : () => {
          console.log("Click dose nothing");
        };
  }
}
