import { useContext } from "react";
import footerContext from "./FooterContext";
import "./footer.scss";

export default function Footer({ footerLinks, marginAndPadding, logo }: any) {
  const { socialLinks, footerCustomMarginPadding } = useContext(footerContext);

  return (
    <footer
      className={`retailer-ui-container`}
      style={marginAndPadding ? marginAndPadding : {}}
    >
      <button onClick={logo.onClick} className="logo_button">
        <img className="footer_logo" src="icon/logo.png" alt="logo" />
      </button>
      <section className="footer_links">
        {footerLinks ? (
          footerLinks.map((footerLink: any, i: number) => {
            const halfPaddingAmount = 1;
            const classes =
              i % 2 !== 0
                ? `text-left pl-${halfPaddingAmount}`
                : `text-right pr-${halfPaddingAmount}`;
            return (
              <button
                className={`footer_link_item`}
                key={i}
                onClick={footerLink.onClick}
              >
                <p className={`footer_link_item ${classes}`}>
                  {footerLink.title}
                </p>
              </button>
            );
          })
        ) : (
          <></>
        )}
      </section>
      <section className="socials_row">
        {socialLinks?.map ? (
          socialLinks.map((socialLink: any, i: number) => (
            <a href={socialLink.socialLink} key={i}>
              <img src={socialLink?.icon?.asset?.url} alt="social link" />
            </a>
          ))
        ) : (
          <></>
        )}
      </section>
    </footer>
  );
}
