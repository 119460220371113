import { useEffect, useState } from "react";
import "./passwordResetConfirmation.scss";

function EnterForgottenEmail({ firstComp }: any) {
  const [email, setEmail] = useState<any>();

  useEffect(() => {
    //Get email from url variable
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    setEmail(email);
  }, []);

  return email ? (
    <div
      className={`retailer-ui-container password-reset-confirmation-container ${
        firstComp ? "first-comp-margin" : ""
      }`}
    >
      <h4>Password for {email} has been successfully changed</h4>
    </div>
  ) : (
    <></>
  );
}

export default EnterForgottenEmail;
