import "./App.css";
import "./App.scss";
import Header from "core/Header";
import Body from "core/Body";
import { FooterProvider, LayouterRetailerFooter } from "core/Footer";
import { ChangeURLProvider } from "components/Utilities";
import SettingsProvider from "components/Settings";
import { isDev } from "components/Utilities";
//@ts-ignore
import { Provider as LayoutToolsProvider } from "layouter-tools";
import { useEffect } from "react";
import { AuthenticationProvider } from "components/Authentication";
import { CartContextProvider } from "components/Cart";
import FlashMessage from "components/FlashMessage/FlashMessage";
import { GlobalProvider } from "./globalContext";

import sectionMap from "./layouterSectionMap";

function App() {
  const layouterToolsOptions = {
    PUBLIC_API_KEY: "bjwi19ta",
    dataset: isDev() ? "development" : "production",
    sectionMap: sectionMap,
  };

  return (
    <LayoutToolsProvider options={layouterToolsOptions}>
      <GlobalProvider>
        <Header />
        <Body />
        <FlashMessage />
        <LayouterRetailerFooter />
      </GlobalProvider>
    </LayoutToolsProvider>
  );
}

export default App;
