import { createContext, useEffect, useState, useContext } from "react";
//@ts-ignore
import { layouterContext } from "layouter-tools";
import { join, resolve } from "path";

const footerContext = createContext<any>({});

export default footerContext;

export function FooterProvider({ children }: any) {
  //@ts-ignore
  const { client } = useContext(layouterContext) || {};
  const [footerLinks, setFooterLinks] = useState<any>();
  const [socialLinks, setSocialLinks] = useState<any>();
  const [footerCustomMarginPadding, setFooterCustomMarginPadding] =
    useState<any>();

  useEffect(() => {
    (async () => {
      if (!client) return;
      const footer: any = await getFooter(client);
      setFooterLinks(footer.links);
      setSocialLinks(footer.socialLinks);
      setFooterCustomMarginPadding(footer.sectionMarginAndPadding);
    })();
  }, [client]);

  return (
    <footerContext.Provider
      value={{ footerLinks, socialLinks, footerCustomMarginPadding }}
    >
      {children}
    </footerContext.Provider>
  );
}

async function getFooter(client: any) {
  if (!client || !client.fetch) return null;
  return new Promise((resolve, reject) => {
    const query = `*[_type == "footer"]{
                    ...,
                    links[]{
                      ...,
                      ...,
                      pageChange->,
                    },
                    socialLinks[]{
                      ...,
                      icon{
                      ...,
                        asset->
                      }
                    }
                  }`;
    client
      .fetch(query)
      .then((footerLinks: any) => {
        if (
          !footerLinks ||
          !footerLinks[0] ||
          typeof footerLinks[0] !== "object"
        )
          return reject(null);
        resolve(footerLinks[0]);
      })
      .catch((err: any) => {
        console.error(err);
        reject(null);
      });
  });
}

function getAppPages(client: any) {
  return new Promise((resolve, reject) => {
    client
      .fetch(`*[_type == "page"]{slug}`)
      .then((appPages: any) => {
        resolve(appPages);
      })
      .catch((err: any) => {
        console.error(err);
        resolve(null);
      });
  });
}

function joinFooterLinksPages(
  partialPopulatedFooterLinks: any,
  pagesSlugs: any
) {
  return partialPopulatedFooterLinks.map((footerLink: any) => {
    return {
      ...footerLink,
      links: footerLink.links.map((link: any) => {
        return {
          ...link,
          link: pagesSlugs.filter(
            (page: any) => page._id === link.link._ref
          )[0],
        };
      }),
    };
  });
}
