import MassiveHeader from "./MassiveHeader";
import useAsset from "components/Utilities/useAsset";

function LayouterRetailerMassiveHeader({
  title,
  backgroundImage,
  textColor,
  firstComp,
}: any) {
  const backgroundImageURL = useAsset(backgroundImage?.asset);
  return (
    <MassiveHeader
      title={title}
      backgroundImage={backgroundImageURL}
      textColor={textColor}
      firstComp={firstComp}
    />
  );
}

export default LayouterRetailerMassiveHeader;
