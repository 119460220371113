const createMarginPaddingStyles = (props: any) => {
  if (!props) return;
  let mPOB: any = {};
  if (props.applyCustomMargin)
    mPOB.marginTop = props.marginTop + props.marginUnits;
  if (props.applyCustomMargin)
    mPOB.marginBottom = props.marginBottom + props.marginUnits;
  if (props.applyCustomMargin)
    mPOB.marginLeft = props.marginLeft + props.marginUnits;
  if (props.applyCustomMargin)
    mPOB.marginRight = props.marginRight + props.marginUnits;

  if (props.applyCustomPadding)
    mPOB.paddingTop = props.paddingTop + props.paddingUnits;
  if (props.applyCustomPadding)
    mPOB.paddingBottom = props.paddingBottom + props.paddingUnits;
  if (props.applyCustomPadding)
    mPOB.paddingLeft = props.paddingLeft + props.paddingUnits;
  if (props.applyCustomPadding)
    mPOB.paddingRight = props.paddingRight + props.paddingUnits;

  return mPOB;
};

export default createMarginPaddingStyles;
