import { localStorage } from "utilities";

const FAILURE_PATH = "images/flash_error_background_01.png";
const ERROR_PATH = "icon/error_white.svg";

type FlashRequest = {
  type: "success" | "error";
  title?: string;
  message: string;
  timeOut?: number;
  duration?: number;
};

function useFlash() {
  return { flashRequest, abortTimeout };
}

function flashRequest(flashRequest: FlashRequest): Promise<string | void> {
  const { timeOut } = flashRequest;
  return new Promise((resolve, reject) => {
    const originalFlash = document.getElementById("original_flash");
    const flashID = generateOriginalFlashID();
    var newFlash = originalFlash?.cloneNode(true) as HTMLElement | null;

    if (!newFlash)
      return reject(
        console.error("Original flash message element is not found")
      );

    const flashIDs = getFlashIDs();
    newFlash?.setAttribute("id", flashID);

    newFlash.style.zIndex = 100 + flashIDs.length + "";

    document.body.appendChild(newFlash);
    newFlash.classList.remove("hidden");
    setTimeout(() => {
      if (!newFlash)
        return reject(console.error("New flash message element is not found"));
      if (timeOut) {
        setTimeout(() => {
          if (!(getAbortIDs() || []).includes(flashID))
            presentFlashEle(newFlash as HTMLElement, flashRequest);
        }, timeOut);
        return resolve(flashID);
      }
      presentFlashEle(newFlash, flashRequest);
      return resolve(flashID);
    }, 0.001);
  });
}

function presentFlashEle(newFlash: HTMLElement, flashRequest: any) {
  const { duration } = flashRequest;
  populateFlashElement(newFlash, flashRequest);
  addExitEventListener(newFlash);
  newFlash.classList.add("shown");
  setTimeout(() => {
    newFlash?.classList?.remove("shown");
    setTimeout(() => {
      newFlash?.remove();
    }, 5000);
  }, duration || 8000);
}

function addExitEventListener(flashEle: HTMLElement) {
  flashEle.querySelector(".exit_button")?.addEventListener("click", () => {
    flashEle.classList.remove("shown");
    setTimeout(() => {
      flashEle?.remove();
    }, 5000);
  });
}

function populateFlashElement(flashEle: any, flashRequest: FlashRequest) {
  if (flashRequest.type === "error") {
    flashEle.querySelector(".background_image").src = FAILURE_PATH;
    flashEle.querySelector(".icon").src = ERROR_PATH;
  }

  flashEle.querySelector(".text_side h1").textContent =
    flashRequest.title ||
    flashRequest.type[0].toUpperCase() + flashRequest.type.slice(1);
  flashEle.querySelector(".text_side p").textContent = flashRequest.message;
}
function abortTimeout(flashID: string) {
  let existingAbortIDs = (localStorage.getObject("abortIDs") || []) as any;
  existingAbortIDs.push(flashID);
  localStorage.set("abortIDs", existingAbortIDs);
}

function getAbortIDs(): string[] {
  return (localStorage.getObject("abortIDs") || []) as string[];
}

function generateOriginalFlashID(): string {
  const existingIDs = (localStorage.getObject("flashIDs") as string[]) || [];
  let newID = generateRandomID();
  while (existingIDs.includes(newID)) {
    newID = generateRandomID();
  }
  existingIDs.push(newID);
  localStorage.set("flashIDs", existingIDs);
  return newID;
}

function generateRandomID(): string {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

function getFlashIDs(): string[] {
  return (localStorage.getObject("flashIDs") || []) as string[];
}

export default useFlash;
