//@ts-ignore
import { Body as LayoutBody } from 'layouter-tools';
import HTMLText from 'components/HTMLTest';
import './style.scss';

export default function View() {
	return (
		<div className='body-container'>
			<LayoutBody />
		</div>
	);
}
