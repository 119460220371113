import { useState, useEffect, useContext } from "react";
import "./style.scss";
import { getURLFromSanityRefrence } from "components/Utilities";
import { changeURLContext } from "components/Utilities";
//@ts-ignore
import { layouterContext } from "layouter-tools";
import FunnelItem03 from "./FunnelItem03";
export default function FunnelView(props: any) {
  const [BGURL, setBGURL] = useState<any>(null);
  //@ts-ignore
  const { client } = useContext(layouterContext) || {};
  const { handleActionButton } = useContext(changeURLContext);

  useEffect(() => {
    //TODO: Refactor these effects to be robust custom hooks
    if (BGURL) return;
    getURLFromSanityRefrence(props.backgroundImage, client)
      .then((url: any) => {
        if (url) setBGURL(url);
        else setBGURL("Failed to load image");
      })
      .catch(console.error);
  }, [BGURL]);

  return (
    <FunnelItem03
      BGURL={BGURL}
      BGOpacity={props.backgroundOpacityPercentage}
      BGColor={props.backgroundOpacityColor}
      actionText={props.actionButton}
      onAction={() => {
        handleActionButton(props);
      }}
      title={props.title}
      subtitle={props.subtitle}
      customMarginPadding={props.sectionMarginAndPadding}
    />
  );
}
