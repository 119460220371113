function RemoveButton({ onRemoveFromCart }: any) {
  return (
    <button
      className="remove_button"
      onClick={() => {
        onRemoveFromCart();
      }}
    >
      <img alt="delete" src="/icon/delete_white.svg" />
    </button>
  );
}

export default RemoveButton;
