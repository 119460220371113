import { useState, useEffect, useContext } from "react";
import { authRoute } from "utilities";
import { changeURLContext } from "components/Utilities";
import { settingsContext } from "components/Settings";
import { useFlash as createFlash } from "components/FlashMessage";
import { auth } from "components/Utilities";
import "./enterNewPassword.scss";

function EnterNewPassword({ firstComp }: any) {
  const [resetPasswordToken, setResetPasswordToken] = useState<string | null>();
  const [email, setEmail] = useState<string | null>();
  const [newPassword, setNewPassword] = useState<string | null>();
  const { changeURL } = useContext(changeURLContext);
  const { settings }: any = useContext(settingsContext);
  const { flashRequest } = createFlash();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetPasswordToken = urlParams.get("resetPasswordToken");
    const email = urlParams.get("email");
    setEmail(email);
    setResetPasswordToken(resetPasswordToken);
  });
  return (
    <div
      className={`retailer-ui-container enter_new_password ${
        firstComp ? "first-comp-margin" : ""
      }`}
    >
      <h4>
        Please enter new password for account {email || "(No email found)"}
      </h4>
      <div className="narrow-form">
        <label htmlFor="new_password">New password</label>
        <input
          type="password"
          name="password"
          id="new_password"
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
        <button onClick={onResetPassword}>Reset password</button>
      </div>
    </div>
  );

  function onResetPassword() {
    // rest password route /resetPassword
    // body: { email, resetPasswordToken, newPassword }
    if (!newPassword) return invalidPasswordMessage(newPassword);
    if (!auth.validatePassword(newPassword))
      return invalidPasswordMessage(newPassword);

    fetch(`${authRoute}/user/resetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, resetPasswordToken, newPassword }),
    })
      .then(async (res) => {
        if (!res.ok) return Promise.reject(await res.json());
        return res.json();
      })
      .then((fetchRes) => {
        console.log("Successfully reset password: ", fetchRes);
        //redirect to confirmaion page here
        const passwordChangeConfirmationPageSlug =
          settings?.resetPasswordConfirmation?.pageChange?.slug?.current;
        //changeURL
        // TODO: Have the page to change to be dependent from settings context that is dependent from Sanity CMS
        if (!passwordChangeConfirmationPageSlug) {
          flashRequest({
            message: "Password reset successfully",
            type: "success",
            title: "Success",
          });
          changeURL("changePage", "/signin");
          return;
        }
        changeURL(
          "changePage",
          `${passwordChangeConfirmationPageSlug}?email=${email}`,
          ""
        );
      })
      .catch((err) => {
        console.error("Error resetting password: ", err);
        alert("redirect to confirmaion page here - Failed to reset password");
      });
  }
}

function invalidPasswordMessage(password: any) {
  const { flashRequest } = createFlash();
  flashRequest({
    message: `Password must be at least 8 characters long. Password "${password}" is invalid.`,
    type: "error",
    title: "Invalid password",
  });
  return;
}

export default EnterNewPassword;
