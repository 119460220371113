import { useContext } from "react";
import SignUp from "./SignUp";
import { authRoute, localStorage } from "utilities";
import authenticationContext from "components/Authentication";
import { changeURLContext } from "components/Utilities";
import useForgotPassword from "components/useForgotPassword";
import { useFlash } from "components/FlashMessage";

export default LayouterRetailerSignUp;

function LayouterRetailerSignUp({ firstComp }: any) {
  const { setUser }: any = useContext(authenticationContext);
  const { changeURL } = useContext(changeURLContext);
  const { abortTimeout, flashRequest } = useFlash();
  const forgotPassword = useForgotPassword();

  return (
    <SignUp
      className={`${firstComp ? "first-comp" : ""}`}
      fetchSignUp={fetchSignUp}
      forgotPassword={forgotPassword}
    />
  );

  async function fetchSignUp(user: any, context: any): Promise<string | null> {
    const { timeoutFlashID } = context || {};
    return await fetch(`${authRoute}/user/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then(async (res) => {
        if (!res.ok) return Promise.reject(await res.json());
        return res.json();
      })
      .then((res) => {
        if (typeof timeoutFlashID === "string") abortTimeout(timeoutFlashID);
        if (res.JWT && res.rememberMe) localStorage.set("userJWT", res.JWT);
        setUser({ ...res.user, JWT: res.JWT || "unset" });
        changeURL("changePage", "/homepage");
        flashRequest({
          type: "success",
          message: "You have successfully signed up",
          title: "Success",
        });
        return null;
      })
      .catch((err) => {
        if (typeof timeoutFlashID === "string") abortTimeout(timeoutFlashID);
        flashRequest({
          type: "error",
          message:
            "Either your credentials are incorrect or the authentication server now working",
          title: "Failed to sign up",
        });
        return err.message;
      });
  }
}
