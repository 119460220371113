import FunnelItem01 from "./FunnelItem01";
import { createMarginPaddingStyles } from "components/Utilities";

function LayouterRetailerFunnelItem01(props: any) {
  const { sectionMarginAndPadding } = props || {};
  const marginPaddingStyles = createMarginPaddingStyles(
    sectionMarginAndPadding
  );
  return <FunnelItem01 marginPaddingStyles={marginPaddingStyles} />;
}

export default LayouterRetailerFunnelItem01;
