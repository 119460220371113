import React, { useEffect, useState, useRef } from "react";
import { sanityClient as client } from "utilities";

export default function useRawProducts(dependencyRef: any = {}) {
  const [rawProducts, setRawProducts] = useState([]);
  const [refresher, setRefresher] = useState<any>();
  const apiStage = useRef("asleep");

  useEffect(() => {
    (async () => {
      if (apiStage.current === "loading") return;
      if (apiStage.current === "asleep") {
        apiStage.current = "loading";
        const rawProducts = await fetchRawProducts();
        setRawProducts(rawProducts);
        apiStage.current = "awake";
      }
      if (apiStage.current === "awake") {
        //if passes checks. recall api and update state
      }
    })();
  }, [rawProducts, refresher]);

  return rawProducts;
}

async function fetchRawProducts() {
  try {
    const response = await client.fetch(
      `*[_type == "products"]{...,thumbnail{asset->{url}}}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
}
