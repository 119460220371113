import "./confirmation.scss";
import { FluidCart } from "components/Cart";

export default function Confirmation({ firstComp, cart }: any) {
  return (
    <div
      className={`confirmation-container retailer-ui-container ${
        firstComp ? "first-comp" : ""
      }`}
    >
      <h3>Congratulations on your purchase!</h3>
      <FluidCart
        excludeCheckout={true}
        excludePrice={true}
        excludeNotes={true}
        cart={cart}
      />
      <p className="context">Your order confirmation Number is 2342343209</p>
      <p className="context">
        More information about this purchase as been sent to johndoe@gmail.com
      </p>
    </div>
  );
}
