import { useContext } from "react";
import { cartContext } from "components/Cart";
import Product from "./Product";
import useProduct from "./useProduct";
import { changeURLContext } from "components/Utilities";
import { settingsContext } from "components/Settings";
import { useFlash } from "components/FlashMessage";

export default function LayouterRetailerProduct(props: any) {
  const { changeURL } = useContext(changeURLContext);
  const { settings }: any = useContext(settingsContext);
  const { addToCart, addToBuyNowCart }: any = useContext(cartContext);
  const product: object = useProduct();
  const { flashRequest } = useFlash();

  //Note: props is practically empty. props represents the product section, not the product itself.

  return (
    <Product
      firstComp={props.firstComp}
      {...product}
      onAddToCart={onAddToCart}
      onBuyNow={onBuyNow}
    />
  );
  function onAddToCart() {
    //TODO: Have the product being added to cart be passed in as an argument to this function
    addToCart(tempFormatProductForAuthServer(product));
    flashRequest({
      type: "success",
      message: "Item successfully added to cart",
      title: "Added to cart",
    });
    setTimeout(() => {
      window.history.back();
    }, 1000);
  }

  function onBuyNow() {
    if (!settings?.checkoutAction?.pageChange?.slug?.current)
      return (() => {
        flashRequest({
          type: "error",
          message: "The checkout page is not set. This is our fault.",
          title: "Error",
        });
      })();
    console.log("Adding:", tempFormatProductForAuthServer(product));
    addToBuyNowCart(tempFormatProductForAuthServer(product));
    changeURL("changePage", settings.checkoutAction.pageChange.slug.current);
  }
}

function tempFormatProductForAuthServer(product: any) {
  if (product.hasOwnProperty("categories")) {
    product["selectedCategories"] = product["categories"];
    delete product["categories"];
  }
  return product;
}
