import "./enterForgottenEmail.scss";
import { auth } from "components/Utilities";
import { useState } from "react";
import { useFlash } from "components/FlashMessage";

function EnterForgottenEmail({ firstComp, onSendEmail }: any) {
  const [email, setEmail] = useState<any>();
  const { flashRequest } = useFlash();
  return (
    <div
      className={`retailer-ui-container enter_forgotten_email ${
        firstComp ? "first-comp-margin" : ""
      }`}
    >
      <h4>Enter the email of the password you would like to reset</h4>
      <div className="narrow-form">
        <label>Email</label>
        <input
          type="text"
          onChange={(e: any) => {
            setEmail(e?.target?.value);
          }}
        />
        <button
          onClick={() => {
            if (auth.validateEmail(email)) onSendEmail(email);
            else
              flashRequest({
                message: `${email} is an invalid email`,
                type: "error",
                title: "Invalid Email",
              });
          }}
        >
          Send Reset Link
        </button>
      </div>
    </div>
  );
}

export default EnterForgottenEmail;
