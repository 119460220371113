import ProductDisplay from "./ProductDisplay";
import Filter from "components/Filter";
import useFilterdProducts from "./useFilterdProducts";
import { createMarginPaddingStyles } from "components/Utilities";
import { useRef, useState, useEffect } from "react";
import useFormatedFilter from "./useFormatedFilter";
import FilterType, {
  Category,
  Color,
  SingleCategory,
  SingleColor,
  SingleFilter,
} from "./FormatedFilterType";

export default function LayouterRetailerProductDisplay(props: any) {
  const { sectionMarginAndPadding } = props || {};
  const formatedFilterPack = useFormatedFilter(props.defaultFilters);
  let initialFormatedFilter = formatedFilterPack[0] as FilterType;
  const [formattedFilter, setFormatedFilter] = useState(initialFormatedFilter);
  const filterdProducts = useFilterdProducts(
    props.defaultFilters,
    formattedFilter
  );

  useEffect(() => {
    setFormatedFilter(initialFormatedFilter);
  }, [initialFormatedFilter]);

  const marginPaddingStyles = createMarginPaddingStyles(
    sectionMarginAndPadding
  );
  const randomKey = useRef(Math.random());

  return (
    <div style={marginPaddingStyles ? { ...marginPaddingStyles } : {}}>
      <Filter
        showFilterByPrice={true}
        showFilterByStyle={true}
        showFilterByColor={true}
        formatedFilter={formattedFilter}
        updateFilter={updateFormatedFilter}
      />
      <ProductDisplay products={filterdProducts} />
    </div>
  );

  function updateFormatedFilter(
    filter: string | { min: number; max: number },
    { action, type }: { action: "add" | "remove" | "update"; type: string }
  ) {
    const formattedFilterCopy = JSON.parse(JSON.stringify(formattedFilter));
    //TODO: Add proper branching for the price filter type
    const normalizedFilter =
      typeof filter === "string" ? filter.replace(/\s/g, "") : filter;
    const newFormatedFilter: any = [];
    const indexOfFilterWithSameType =
      formattedFilterCopy.findIndex(
        (singleFilter: SingleFilter) => singleFilter.type === type
      ) || 0;
    let newSingleFilter: SingleFilter;
    switch (action) {
      case "update":
        newSingleFilter = filterCreators[type](filter);
        console.log(
          "Filter:",
          filter,
          "Type:",
          type,
          "New Filter:",
          newSingleFilter
        );
        for (let i = 0; i < formattedFilterCopy.length; i++) {
          if (i === indexOfFilterWithSameType)
            newFormatedFilter[i] = newSingleFilter;
          else newFormatedFilter.push(formattedFilterCopy[i]);
        }
        break;
      case "remove":
        if (typeof normalizedFilter !== "string") return;
        let currentFormatedFilter;

        for (let i = 0; i < formattedFilterCopy.length; i++) {
          const newChoices: any[] = [];
          currentFormatedFilter = formattedFilterCopy[i];
          if (!currentFormatedFilter) continue;
          const choices = currentFormatedFilter?.choices;
          if (!choices) continue;
          for (let j = 0; j < choices.length; j++) {
            pushExcludedChoices(newChoices, {
              currentChoice: choices[j],
              excludedChoice: normalizedFilter,
            });
            currentFormatedFilter.choices = newChoices;
          }

          newFormatedFilter.push(currentFormatedFilter);
        }

        break;

      case "add":
        if (!filterCreators[type]) {
          return console.error("Invalid filter type:", type);
        }
        newSingleFilter = filterCreators[type](filter, type);

        if (type !== "priceFilter") {
          (newSingleFilter as any).choices = [
            ...((formattedFilterCopy[indexOfFilterWithSameType] as any)
              ?.choices || []),
            {
              category: filter,
              color: type === "colorFilter" ? "not implemented" : undefined,
              enable: true,
            },
          ];
        }

        for (let i = 0; i < formattedFilterCopy.length; i++) {
          if (i === indexOfFilterWithSameType)
            newFormatedFilter.push(newSingleFilter);
          else newFormatedFilter.push(formattedFilterCopy[i]);
        }
        if (indexOfFilterWithSameType < 0) {
          newFormatedFilter.push(newSingleFilter);
        }
        break;
    }

    setFormatedFilter(newFormatedFilter);
  }
}

function pushExcludedChoices(
  newChoices: any[],
  {
    currentChoice,
    excludedChoice,
  }: { currentChoice: any; excludedChoice: string }
) {
  console.log("here");
  if (!currentChoice) return;
  const category = currentChoice?.category;
  if (!category) return;
  const normalizedCategory = category.replace(/\s/g, "").toLowerCase();

  console.log("Comparing:", normalizedCategory, excludedChoice);
  if (normalizedCategory === excludedChoice) {
    return;
  } else newChoices.push(currentChoice);
  return;
}

const filterCreators: any = {
  priceFilter: (filter: any): SingleFilter => ({
    title: "Price Filter",
    type: "priceFilter",
    min: filter.min,
    max: filter.max,
  }),
  colorFilter: (filter: any): SingleFilter => ({
    title: "Color Filter",
    type: "colorFilter",
    choices: [],
  }),
  categoryFilter: (filter: any): SingleFilter => ({
    title: "Category Filter",
    type: "categoryFilter",
    choices: [],
  }),
};
