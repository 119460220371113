/**
 * FormatedFilterType is an array of Price, Color, or Category objects
 * @property {string} title - string;
 * @property {number} min - number;
 * @property {number} max - number;
 */

export type Price = {
  title: string;
  min?: number;
  max?: number;
  type: string;
};

export type SingleColor = {
  category: string;
  color: string;
  enable: boolean;
};
export type Color = {
  title: string;
  choices: SingleColor[];
  type: string;
};

export type SingleCategory = {
  category: string;
  enable: boolean;
};
export type Category = {
  title: string;
  choices: SingleCategory[];
  type: string;
};

export type SingleFilter = Price | Color | Category;

export type FormatedFilterType = (Price | Color | Category)[];

export function getSingleFilterTypeFromSingleFilter(singleFilter: any): string {
  if (singleFilter && "min" in singleFilter) {
    return "Price";
  } else if (singleFilter?.choices?.length && singleFilter?.choices[0]?.color) {
    return "Color";
  } else if (
    singleFilter?.choices?.length &&
    !singleFilter?.choices[0]?.color &&
    singleFilter?.choices[0]?.category
  )
    return "Category";

  return "Unknown";
}

export default FormatedFilterType;
