import { createContext } from "react";
import Header from "core/Header";
import Body from "core/Body";
import { FooterProvider, LayouterRetailerFooter } from "core/Footer";
import { ChangeURLProvider } from "components/Utilities";
import SettingsProvider from "components/Settings";
import { isDev } from "components/Utilities";
//@ts-ignore
import { Provider as LayoutToolsProvider } from "layouter-tools";
import { AuthenticationProvider } from "components/Authentication";
import { CartContextProvider } from "components/Cart";
import FlashMessage from "components/FlashMessage/FlashMessage";

const globalContext = createContext<any>({});

export function GlobalProvider({ children }: any) {
  return (
    <globalContext.Provider value={{}}>
      <SettingsProvider>
        <ChangeURLProvider>
          <AuthenticationProvider>
            <CartContextProvider>
              <FooterProvider>{children}</FooterProvider>
            </CartContextProvider>
          </AuthenticationProvider>
        </ChangeURLProvider>
      </SettingsProvider>
    </globalContext.Provider>
  );
}

export default globalContext;
