import { useEffect, useState, useContext } from "react";
import useRawProducts from "./useRawProducts";
import productCardType from "../ProductCard/productCardType";
import { changeURLContext } from "components/Utilities";
import Filter from "./FormatedFilterType";

export default function useFormatedProducts() {
  const rawProducts = useRawProducts();
  const [formatedProducts, setFormatedProducts] = useState<productCardType[]>(
    []
  );
  const { changeURL } = useContext(changeURLContext);

  useEffect(() => {
    const formatedProducts = getFormatedProducts(rawProducts);
    setFormatedProducts(formatedProducts);
  }, [rawProducts]);

  return formatedProducts;

  function getFormatedProducts(rawProducts: any): productCardType[] {
    let formatedProducts: productCardType[] = [];
    for (let i = 0; i < rawProducts.length; i++) {
      const rawProduct = rawProducts[i];
      let formatedProduct = {
        displayImage: rawProduct?.thumbnail?.asset?.url,
        title: rawProduct?.title,
        price: rawProduct?.price,
        subtitle: rawProduct?.subtitle,
        onClick: () => {
          changeURL("changePage", "/product?productid=" + rawProduct?._id, "");
        },
        categories: rawProduct?.categories,
      };
      if (formatedProduct) formatedProducts.push(formatedProduct);
    }
    return formatedProducts;
  }
}
