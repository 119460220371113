import { useContext, useState, useEffect, useRef } from "react";
import Checkout from "./Checkout";
import { cartContext } from "components/Cart";
import authenticationContext from "components/Authentication";
import { auth, getDiscountObjectFromCode } from "components/Utilities";
import { authRoute } from "utilities";
import { useFlash } from "components/FlashMessage";
import { pricing } from "components/Utilities";
import { settingsContext } from "components/Settings";
import { changeURLContext } from "components/Utilities";
import { loadScript } from "@paypal/paypal-js";

export default function LayouterRetailerCart({ firstComp }: any) {
  const {
    populatedCart,
    buyNowCart,
    resetBuyNowCart,
    removeFromCart,
    setLastPurchase,
  }: any = useContext(cartContext);
  const [buyNowCartBuffer, setBuyNowCartBuffer] = useState(null);
  const [checkout, giveCheckout] = useState();
  const { user }: any = useContext(authenticationContext);
  const { settings }: any = useContext(settingsContext);
  const { flashRequest } = useFlash();
  const { changeURL } = useContext(changeURLContext);
  const [shippingStatus, setShippingStatus] =
    useState<ShippingStatus>("changesDetected");
  const mostRecentID = useRef(0);
  const [carrierPricing, setCarrierPricing] = useState<any>(null);
  const discountCodeMostRecentID = useRef(0);
  const [discountCodeStatus, setDiscountCodeStatus] = useState<any>(null);
  const [discountAmount, setDiscountAmount] = useState<any>(null);
  const [discountCode, setDiscountCode] = useState<any>(null);
  const [paypal, setPaypal] = useState<any>(null);
  const paypalRoutes = {
    createOrder: `${authRoute}/user/createpaypalorder`,
    onApprove: `${authRoute}/user/checkoutCart`,
  };

  //TODO: move this to settings
  const tax = 0.2;
  const stripePercentage = 0.03;
  const ESTIMATED_SHIPPING = 20;

  const shipping = pricing.roundToTwoDecimalPlaces(
    ((settings?.automaticShippingServiceFee || 0) +
      (carrierPricing || ESTIMATED_SHIPPING)) *
      (1 + stripePercentage)
  );

  useEffect(() => {
    console.log("checkout:", checkout);
    const discountCodeLocal: any = ((checkout || {}) as any).discountCode;
    if (discountCodeLocal != discountCode) setDiscountCode(discountCodeLocal);
    setShippingStatus("changesDetected");
    const shippingID = ++mostRecentID.current;
    setTimeout(async () => {
      if (shippingID !== mostRecentID.current) return;
      setShippingStatus("fetching");
      let shippingPrice: any = {};

      try {
        shippingPrice = (await calculateCarrierPricing(checkout)) || {};
      } catch (err) {
        console.log("err:", err);
      }
      console.log("shippingPrice:", shippingPrice);
      if (shippingID !== mostRecentID.current) return;
      const { cheapestRate } = shippingPrice;
      if (!cheapestRate) return failed();
      console.log("cheapestRate:", cheapestRate);
      setCarrierPricing(cheapestRate);
      setShippingStatus("success");
    }, 5000);

    function failed() {
      if (shippingID !== mostRecentID.current) return;
      setShippingStatus("error");
    }
  }, [checkout]);

  useEffect(() => {
    if (discountCode === null || "" || undefined) {
      setDiscountCodeStatus("");
      return setDiscountAmount(null);
    }
    setDiscountCodeStatus("Analyzing Coupon");
    const discountCodeID = ++discountCodeMostRecentID.current;
    setTimeout(async () => {
      if (discountCodeID !== discountCodeMostRecentID.current) return;
      const isValid = await isDiscountCodeValid(discountCode, user?.email);
      if (!isValid) return setDiscountCodeStatus("Coupon is Invalid");

      const discountObject = getDiscountObjectFromCode(discountCode, settings);
      const { discount } = discountObject || {};
      if (!discount) return setDiscountCodeStatus("Coupon is Invalid");
      setDiscountAmount(discount);
      setDiscountCodeStatus("Coupon is Valid. Applying Discount of $");
    }, 8000);
  }, [discountCode]);

  useEffect(() => {
    if (!buyNowCart || !buyNowCart.length) return;
    setBuyNowCartBuffer(buyNowCart);
    resetBuyNowCart();
  }, [buyNowCart]);

  useEffect(() => {
    loadScript({
      clientId:
        "ASoj7mXZDsS4rYkgn7uofXGHFIYZptkvXlSyxa_4YAUP0QsMzkhlhgIjHyYeTDpaEjcO-AY_6tjxoTir",
    })
      .then((paypal: any) => {
        setPaypal(paypal);
      })
      .catch((error) => {
        console.error("failed to load the PayPal JS SDK script", error);
      });
  }, []);

  const cartToUse = buyNowCartBuffer ? buyNowCartBuffer : populatedCart;

  const subTotal =
    pricing.getSubtotalFromCart(cartToUse) * (1 + (stripePercentage || 0));

  const subTotalWithShipping = pricing.getSubtotalWithShipping(
    subTotal,
    shipping
  );
  const taxAmount = pricing.calculateTaxes(subTotalWithShipping, tax);
  const total = pricing.roundToTwoDecimalPlaces(
    subTotalWithShipping + taxAmount
  );

  return (
    <Checkout
      cartProps={{
        subTotal,
        subTotalWithShipping,
        taxAmount,
        total,
        shipping,
        cart: cartToUse,
        shippingStatus: shippingStatus,
        discountAmount,
      }}
      firstComp={firstComp}
      user={user}
      onPurchase={onPurchase}
      onRemoveFromCart={removeFromCart}
      giveCheckout={giveCheckout}
      discountAmount={discountAmount}
      discountCodeStatus={discountCodeStatus}
      paypal={paypal}
      paypalRoutes={paypalRoutes}
      JWT={auth.getJWT()}
    />
  );

  function calculateCarrierPricing(address: any): any {
    if (!address) return null;
    return new Promise((resolve, reject) => {
      fetch(`${authRoute}/shipping/getRate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        //TODO make all data from this body dynamic
        body: JSON.stringify({
          shipTo: {
            name: `${address.firstName} ${address.lastName}`,
            addressLine1: address.streetAddress1,
            addressLine2: address.streetAddress2,
            postalCode: address.zipCode,
            countryCode: address.country,
            phone: "4242019017",
          },
          packageDetails: {
            weight: {
              value: 9.0,
              unit: "ounce",
            },
            dimensions: {
              unit: "inch",
              length: 12,
              width: 7.1,
              height: 6,
            },
          },
        }),
      })
        .then(async (res: any) => {
          if (!res.ok) return Promise.reject(await res.json());
          return res.json();
        })
        .then((rate) => {
          const newRate = {
            cheapestRate: rate.cheapestRate * cartToUse.length,
            cheapestRateObj: rate.cheapestRateObj,
          };
          resolve(newRate);
        })
        .catch((err: any) => {
          console.error(err);
          reject(null);
        });
    });
  }

  // @ts-ignore
  function onPurchase(checkout: any) {
    if (shippingStatus !== "success")
      return flashRequest({
        type: "error",
        message:
          shippingStatus === "changesDetected" || shippingStatus === "fetching"
            ? "Please wait for shipping to be calculated"
            : "Shipping could not be calculated. Please revise your address.",
        title: "Shipping not calculated",
      });
    const errorMessage = validateCheckout(checkout);

    if (errorMessage) {
      flashRequest({
        type: "error",
        message: errorMessage,
        title: "Validation Failed",
      });
      return;
    }

    const JWT = JSON.parse(JSON.stringify(auth.getJWT()));
    const cart = cartToUse;

    flashRequest({
      type: "success",
      message: "We are processing your purchase!",
      title: "Processing",
    });

    console.log("Shit!:", { user, checkout, JWT, cart });

    console.log("paypalOrderID:", typeof checkout?.paypalOrderID);

    fetch(`${authRoute}/user/checkoutCart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        user,
        checkout: {
          ...checkout,
          paypalOrderID:
            //@ts-ignore
            typeof checkout?.paypalOrderID === "string"
              ? checkout.paypalOrderID
              : null,
        },
        JWT,
        cart,
      }),
    })
      .then(async (res) => {
        if (!res.ok) return Promise.reject(await res.json());
        return res.json();
      })
      .then(() => {
        setLastPurchase(cart);
        onSuccessfulCheckout();
      })
      .catch((err) => {
        flashRequest({
          type: "error",
          message: "Failed to complete purchase",
        });
        console.error(err.message);
      });
  }
  function onSuccessfulCheckout() {
    if (!settings?.purchaseConfirmationAction?.pageChange?.slug?.current)
      return flashRequest({
        type: "error",
        message:
          "Your purchase was successful, but the redirect page was not set. Please contact support.",
        title: "Failed to redirect to confirmation page",
      });
    console.log(
      "settings?.checkoutAction?.pageChange:",
      settings?.purchaseConfirmationAction?.pageChange
    );
    changeURL(
      "changePage",
      settings?.purchaseConfirmationAction?.pageChange?.slug?.current,
      ""
    );
    return;
  }
}

function validateCheckout(checkout: any): string | null {
  const {
    firstName,
    lastName,
    email,
    streetAddress1,
    city,
    state,
    zipCode,
    cardNumber,
    expirationDate,
    cvc,
    country,
    phone,
    paypalOrderID,
  } = checkout;
  const validFirstName = firstName.length > 0;
  const validLastName = lastName.length > 0;
  const validEmail = auth.validateEmail(email);
  const validStreetAddress1 = streetAddress1.length > 0;
  const validCity = city.length > 0;
  const validState = state.length > 0;
  const validZipCode = zipCode.length > 0;
  const validCardNumber = cardNumber.length > 0;
  const validExpirationDate = expirationDate.length > 0;
  const validCVC = cvc.length > 0;
  const validCountry = country.length > 0;
  const validPhone = phone && phone?.length >= 10;

  if (!validFirstName) return "First name is required";
  if (!validLastName) return "Last name is required";
  if (!validEmail) return "Email is invalid";
  if (!validStreetAddress1) return "Street address is required";
  if (!validCity) return "City is required";
  if (!validState) return "State is required";
  if (!validZipCode) return "Zip code is required";

  if (!validCountry) return "Country is required";
  if (!validPhone) return "Phone number is required";
  if (paypalOrderID) return null;
  if (!validCardNumber) return "Card number is required";
  if (!validExpirationDate) return "Expiration date is required";
  if (!validCVC) return "CVC is required";
  return null;
}

function isDiscountCodeValid(discountCode: any, email: string) {
  if (!discountCode) return false;
  if (!email) return false;
  return new Promise((resolve, reject) => {
    fetch(`${authRoute}/discountCode/isValid`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: discountCode, email }),
    })
      .then(async (res: any) => {
        if (!res.ok) return Promise.reject(await res.json());
        return res.json();
      })
      .then((res) => {
        const { isValid } = res;
        resolve(isValid);
      })
      .catch((err: any) => {
        console.error(err);
        reject(null);
      });
    return true;
  });
}

type ShippingStatus = "changesDetected" | "fetching" | "success" | "error";
