import React from "react";
import "./cart.scss";
import RemoveButton from "./RemoveButton";
const placeholderProduct = "/dev/product-thumbnail.png";

export default function MobileCart({
  excludeCheckout,
  excludePrice,
  excludeNote,
  onRemoveFromCart,
  cart,
  stripePercentage,
  shipping,
  taxAmount,
  subTotal,
  total,
  shippingStatus = "unknown",
  capitalizeAndAddSpaces,
  onCheckout,
}: any) {
  console.log("cart", cart);
  return (
    <div className="mobile-container">
      <div className="gray-background">
        {cart ? (
          cart.map((cartItem: any, i: number) => {
            return <CartItem key={i} cartItem={cartItem} />;
          })
        ) : (
          <></>
        )}
        <CartItem />
        <hr />
        {!excludeNote ? (
          <>
            <label className="add-note">Add Note To Order</label>
            <textarea />
          </>
        ) : (
          <></>
        )}
        <hr />
        {!excludePrice ? (
          <>
            <div className="detail-container">
              <label>Subtotal</label>
              <p>${subTotal}</p>
              <label>Estimated Shipping</label>
              <p>
                {shippingStatus !== "success" && shippingStatus !== "unknown"
                  ? capitalizeAndAddSpaces(shippingStatus)
                  : `$${shipping}` || "$0"}
              </p>
              <label>Taxes</label>
              <p>${taxAmount}</p>
            </div>
            <hr />

            <div className="detail-container">
              <label>
                <strong>Total</strong>
              </label>
              <p>
                {shippingStatus !== "success" && shippingStatus !== "unknown"
                  ? capitalizeAndAddSpaces(shippingStatus)
                  : `Total $${total}` || "Total $0"}
              </p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {!excludeCheckout ? (
        <button onClick={onCheckout} className="checkout_button">
          CHECKOUT
        </button>
      ) : (
        <></>
      )}
    </div>
  );

  function CartItem({ cartItem }: any) {
    console.log("cartItem", cartItem);
    if (!cartItem) return <></>;
    const thumbnail = cartItem?.thumbnail?.asset?.url
      ? cartItem?.thumbnail?.asset?.url
      : placeholderProduct;
    const price = roundToTwoDecimalPlaces(
      (cartItem?.price || 0) * ((stripePercentage || 0) + 1)
    );

    return (
      <div className="base-info-container">
        <section className="thumbnail_container">
          <img src={thumbnail} className="thumbnail" />
          <RemoveButton
            onRemoveFromCart={() => {
              onRemoveFromCart(cartItem?.productID);
            }}
          />
          <p className="counter">1</p>
        </section>
        <div>
          <label>{cartItem?.title}</label>
          <p>Size</p>
        </div>
        <p className="price_text_mobile">${price}</p>
      </div>
    );
  }
}

function roundToTwoDecimalPlaces(num: any) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
